import { useState, useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import AppContext from "../context/AppContext";
import "./styles/details.css";
// COMPONENTS

import { Link } from "react-router-dom";
// CSS
import "../constants/global.css";
import "./styles/tranking.css";

import { getAllExchangesById, addRating } from "../api/facom_services";
import CircleRed from "../images/CircleRed.svg";
import LoadingFacom from "../components/atoms/LoadingFacom";
import HistoryRating from "../components/atoms/Rating";
import useQuery from "../helpers/getLocation";
import FloatAlert from "../components/atoms/FloatAlert";
import { removeTags } from "../helpers/removeHtml";
import useGlobalContent from "../hooks/useGlobalContent";
import { getInLocalStorage, setInLocalStorage } from "../helpers/local-storage-manager";
import { useSelector } from "react-redux";
import useContents from "../hooks/useContents";
import useContentsDetails from "../hooks/useContentsDetails";
import { createAdapterDetail } from "../api/adapters/exchangeDetailAdapter";

function DetailsExchange(props) {
  const { profile } = useSelector((state) => state.profileReducer);
  const { language } = profile;
  const { getContents, loadingCont } = useContentsDetails();
  const [rating, setRating] = useState(1);
  const { content } = useContext(AppContext);
  const originPath = getInLocalStorage("origin");
  //!Llamar por query
  let query = useQuery();

  const arrowLeft = "< ";

  //Alerts
  const [successAlertStatus, setSuccessAlertStatus] = useState(false);
  const [wrongAlertStatus, setWrongAlertStatus] = useState([false, false]);

  const [loading, setLoading] = useState(false);
  const [detailsArray, setDetailsArray] = useState({});

  const { replaceGlobalContents } = useGlobalContent(detailsArray);

  useEffect(() => {
    //TODO -> GAURDAR EN LOCAL STOREGE EN TODOS MENOS EN LOS DE RESET PASSWORD Y VALIDATE
    //TODO -> EN LOS BOTONES DE ESTAS DOS VIEWS SI TENIA ALGO GUARDADO EN EL LOCAL STORAGE HACER LA REDIRECCIÓN CON EL PATH GUARDADO
    if (props.origin) {
      setInLocalStorage("origin", props.origin);
    } else {
      setInLocalStorage("origin", "");
    }
    if (props.id) {
      (async () => {
        await getExchangesByID(props.id);
      })();
    } else {
      if (originPath) {
        window.location.replace(`/historic/${originPath}`);
      } else {
        window.location.replace(`/historic`);
      }
    }
  }, [language]);

  const getExchangesByID = async (ExchangeId) => {
    setLoading(!loading);
    try {
      const response = getAllExchangesById(ExchangeId);
      const data = (await response).data;
      setDetailsArray(createAdapterDetail(data));

      getContents(language, data?.result?.group_data?.id);

      setRating(data.result.evaluation);
      return data;
    } catch (error) {
      console.log("error get details");
      console.log(error);
      alertWrongGet(true, false);
    } finally {
      console.log("end");
      setLoading(false);
    }
  };

  const addRatingPut = async (ratingValue) => {
    setLoading(!loading);
    try {
      addRating(props.id, ratingValue);
      alertSuccess();
    } catch (error) {
      console.log("error get details");
      console.log(error);
      alertWrongGet(false, true);
    } finally {
      console.log("end");

      setLoading(false);
    }
  };

  //? ------------------------------ALERTS--------------------------------//

  const alertSuccess = () => {
    setSuccessAlertStatus(true);
  };

  const alertWrongGet = (GetExchange, Rating) => {
    setWrongAlertStatus([GetExchange, Rating]);
  };

  const renderSuccess = () => {
    return (
      <div>
        {successAlertStatus && (
          <FloatAlert
            message={removeTags(`${content["ALERT-SUCCESS-RATING"]}`)}
            show={successAlertStatus}
            setShow={() => {
              setTimeout(function () {
                setSuccessAlertStatus(false);
              }, 4000);
            }}
            variant="success"
          />
        )}
      </div>
    );
  };

  const renderError = () => {
    return (
      <div>
        {(wrongAlertStatus[0] || wrongAlertStatus[1]) && (
          <FloatAlert
            message={
              wrongAlertStatus[0]
                ? removeTags(`${content["ALERT-ERROR-GET-EXCHANGES"]}`)
                : wrongAlertStatus[1]
                ? removeTags(`${content["ALERT-ERROR-RATING"]}`)
                : ""
            }
            show={wrongAlertStatus[0] || wrongAlertStatus[1] ? true : false}
            setShow={() => {
              setTimeout(function () {
                setWrongAlertStatus(false);
              }, 4000);
            }}
          />
        )}
      </div>
    );
  };

  return (
    <div className=" Background_Gray py-5 details">
      <div className="row" style={{ margin: "0" }}>
        <Link to={`/historic/${originPath}`}>
          <h6 className="h6-return" style={{ display: "flex", gap: "10px" }}>
            {arrowLeft}
            <div dangerouslySetInnerHTML={{ __html: content["FORM.ALL.BACK"] }}></div>
          </h6>
        </Link>
        {loading && (
          <div className="py-5 ">
            {" "}
            <LoadingFacom />
          </div>
        )}

        <div className="row">
          {!loading && (
            <>
              <h2 className="h1-stl Center py-lg-5 py-3">
                <div
                  dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.TITLE-DETAIL"]) }}
                  className="main-titles"
                ></div>
              </h2>
              <div className="col-1 col-lg-3"></div>
              <div className="col-10 col-lg-6 Background_White pt-5">
                <div className="row">
                  <div className="col-12 col-lg-1"></div>
                  <div className="col-12 col-lg-10">
                    <div style={{ display: "flex", gap: "5px" }}>
                      <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.CLAIM-ID"]) }}></div>:{" "}
                      {detailsArray?.result?.number}
                    </div>
                    {detailsArray?.result?.status[detailsArray?.result?.status.length - 1].status === "Exchanged" && (
                      <div content style={{ textAlign: "center" }}>
                        <div
                          className="pb-3 pt-4"
                          dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.RATING-TITLE"]) }}
                        ></div>
                        <div
                          dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.RATING-SUB-TITLE"]) }}
                        ></div>
                        <HistoryRating
                          value={rating}
                          onChange={(event, newValue) => {
                            setRating(newValue);
                            addRatingPut(newValue);
                          }}
                        />
                        <div className="pb-5" style={{ width: "60%", margin: "0 auto" }}>
                          <p style={{ fontSize: "12px", float: "left" }}>
                            <div
                              dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.RATING-OP-ONE"]) }}
                            ></div>
                          </p>
                          <p style={{ fontSize: "12px", float: "right" }}>
                            <div
                              dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.RATING-OP-TWO"]) }}
                            ></div>
                          </p>
                        </div>
                      </div>
                    )}
                    {detailsArray?.result?.status[detailsArray?.result?.status.length - 1].status === "Recycled" && (
                      <div content style={{ textAlign: "center" }}>
                        <div
                          className="pb-3 pt-4"
                          dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.RATING-TITLE"]) }}
                        ></div>
                        <div
                          dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.RATING-SUB-TITLE"]) }}
                        ></div>
                        <HistoryRating
                          value={rating}
                          onChange={(event, newValue) => {
                            setRating(newValue);
                            addRatingPut(newValue);
                          }}
                          readOnly
                        />
                        <div className="pb-5" style={{ width: "60%", margin: "0 auto" }}>
                          <p style={{ fontSize: "12px", float: "left" }}>
                            {" "}
                            <div
                              dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.RATING-OP-ONE"]) }}
                            ></div>
                          </p>
                          <p style={{ fontSize: "12px", float: "right" }}>
                            <div
                              dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.RATING-OP-TWO"]) }}
                            ></div>
                          </p>
                        </div>
                      </div>
                    )}

                    {detailsArray?.result?.status?.map((row) => (
                      <div className="py-3">
                        <div style={{ display: "flex" }}>
                          {row.status === "Draft" && ""}
                          {row.status === "Submitted" && (
                            <div style={{ display: "block" }}>
                              <div style={{ display: "flex", alignItems: "flex-start", gap: "10px" }}>
                                <img src={CircleRed} style={{ marginTop: "4px" }}></img>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.SUBMITTED-PART-ONE"], row),
                                  }}
                                ></div>
                              </div>
                            </div>
                          )}
                          {row.status === "Analysis" && (
                            <div style={{ display: "flex", alignItems: "flex-start", gap: "10px" }}>
                              <img src={CircleRed} style={{ marginTop: "4px" }}></img>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.ANALYSIS-PART-ONE"], row),
                                }}
                              ></div>
                            </div>
                          )}
                          {row.status === "Accepted" && (
                            <div style={{ display: "flex", alignItems: "flex-start", gap: "10px" }}>
                              <img src={CircleRed} style={{ marginTop: "4px" }}></img>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.ACCEPTED-PART-ONE"], row),
                                }}
                              ></div>
                            </div>
                          )}
                          {row.status === "Available" && (
                            <div style={{ display: "flex", alignItems: "flex-start", gap: "10px" }}>
                              <img src={CircleRed} style={{ marginTop: "4px" }}></img>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.AVAILABLE-PART-ONE"], row),
                                }}
                              ></div>
                            </div>
                          )}
                          {row.status === "Exchanged" && (
                            <div style={{ display: "flex" }}>
                              {/* {detailsArray?.result?.distributor_data?.evaluation === 0 && (
                                <div style={{ display: "flex" }}>
                                  <img src={CircleRed}></img>
                                  <h5 style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "10px" }}>
                                    {" "}
                                    {content["TABLE-TRACKING-WARRANTY.EXCHANGED-NOT-QUALIFIED"]} ?{" "}
                                  </h5>
                                </div>
                              )}
                              {detailsArray?.result?.distributor_data?.evaluation !== 0 && ( */}
                              <div style={{ display: "flex", alignItems: "flex-start", gap: "10px" }}>
                                <img src={CircleRed} style={{ marginTop: "4px" }}></img>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.EXCHANGED-QUALIFIED"], row),
                                  }}
                                ></div>
                              </div>

                              {/* )} */}
                            </div>
                          )}
                          {row.status === "Refused" && (
                            <div style={{ display: "flex", alignItems: "flex-start", gap: "10px" }}>
                              <img src={CircleRed} style={{ marginTop: "4px" }}></img>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.REFUSED-PART-ONE"], row),
                                }}
                              ></div>
                            </div>
                          )}
                          {row.status === "Recycled" && (
                            <div style={{ display: "flex", alignItems: "flex-start", gap: "10px" }}>
                              <img src={CircleRed} style={{ marginTop: "4px" }}></img>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.RECYCLED-PART-ONE"], row),
                                }}
                              ></div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="col-12 col-lg-1"></div>
                </div>
                <div className="py-3"></div>
              </div>
            </>
          )}
          <div className="col-1 col-lg-2"></div>
        </div>
      </div>
      {renderSuccess()}
      {renderError()}
    </div>
  );
}

export default DetailsExchange;
