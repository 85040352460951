import { useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import AppContext from "../context/AppContext";
import redCircle from "../images/redCircle.svg";
import grayCircle from "../images/GrayCircle.svg";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { ButtonClean, LoadingFacom, ValidationAlert } from "../components";
import { getInLocalStorage, LocalStorageKeys, setInLocalStorage } from "../helpers/local-storage-manager";
import { resendVerificationEmailAPI } from "../api/facom_services";
import { removeTags } from "../helpers/removeHtml";

export default function VerifyEmail({ verificationCode, origin }) {
  const email = getInLocalStorage(LocalStorageKeys.EMAIL);
  const arrowLeft = "< ";
  const [errorSendEmail, setErrorSendEmail] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const [alertWrongMessage, setAlertWrongMessage] = useState("");
  const [successSendEmail, setSuccessSendEmail] = useState(false);

  const { content } = useContext(AppContext);
  const originPath = getInLocalStorage("origin");
  useEffect(() => {
    if (origin) {
      setInLocalStorage("origin", origin);
    } else {
      setInLocalStorage("origin", "");
    }
  }, []);

  const resendValidationEmail = async (emailToSend) => {
    setLoadingSend((prev) => !prev);
    try {
      await resendVerificationEmailAPI(emailToSend);
      setSuccessSendEmail(true);
      setErrorSendEmail(false);
    } catch (error) {
      console.log("error sending email");
      console.log(error);
      setErrorSendEmail(true);
      setSuccessSendEmail(false);
      setAlertWrongMessage(`${error?.response?.data?.message}`);
    } finally {
      console.log("end");
      setLoadingSend(false);
    }
  };

  const RenderError = () => {
    return (
      <>
        {errorSendEmail && (
          <div className="my-3">
            <ValidationAlert message={alertWrongMessage} variant="error" />
          </div>
        )}
      </>
    );
  };
  const RenderSuccess = () => {
    return (
      <>
        {successSendEmail && (
          <div className="my-3">
            <ValidationAlert message={"Email sent"} severity="success" />
          </div>
        )}
      </>
    );
  };

  return (
    <div style={{ margin: "0", padding: "0" }}>
      <div className="Background_Gray p-5">
        <a href={`/login/${originPath}`}>
          <h6 className="h6-return" style={{ display: "flex", gap: "10px" }}>
            {arrowLeft}
            <div dangerouslySetInnerHTML={{ __html: content["FORM.ALL.BACK"] }}></div>
          </h6>
        </a>
        <Card className="mt-5">
          <div className="py-4 px-5">
            <div className="my-4">
              <MailOutlineIcon fontSize="large" />
            </div>
            <RenderError />
            <RenderSuccess />
            <h3 style={{ textTransform: "uppercase" }}>
              <div dangerouslySetInnerHTML={{ __html: content["VERIFY-EMAIL-TITLE"] }}></div>
            </h3>
            <h6 className="mt-3">
              <div dangerouslySetInnerHTML={{ __html: content["VERIFY-EMAIL-DESCRIPTION"] }}></div>
            </h6>
            <div style={{ display: "flex" }} className="mt-3">
              <img src={redCircle}></img>
              <h6 style={{ paddingLeft: "10px", paddingTop: "7px" }}>
                <div dangerouslySetInnerHTML={{ __html: content["VERIFY-EMAIL-REGISTER"] }}></div>
              </h6>
            </div>
            <div style={{ display: "flex" }} className="my-2">
              <img src={redCircle}></img>
              <div style={{ paddingLeft: "10px", display: "flex", gap: "8px" }}>
                <div dangerouslySetInnerHTML={{ __html: content["VERIFY-EMAIL-ACTIVATION"] }}></div> {email}
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <img src={grayCircle}></img>
              <h6 style={{ paddingLeft: "10px", paddingTop: "7px" }}>
                <div dangerouslySetInnerHTML={{ __html: content["VERIFY-EMAIL-ACTIVATE-ACCOUNT"] }}></div>
              </h6>
            </div>

            <div style={{ display: "flex" }} className="mt-3 pb-5">
              <ButtonClean
                message={removeTags(`${content["VERIFY-EMAIL-BUTTON.RESEND"]}`)}
                onClick={() => resendValidationEmail(email)}
                width="230px"
              />
            </div>
          </div>
        </Card>
      </div>
        {loadingSend && (
            <LoadingFacom />
        )}
    </div>
  );
}
