import { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";

// COMPONENTS

import ButtonMain from "../components/atoms/ButtonMain";
import ButtonLoggout from "../components/atoms/ButtonLoggout";
import { Link } from "react-router-dom";

// CSS
import "../constants/global.css";
import "./styles/login.css";

import MainIcon1 from "../images/MainIcon1.svg";
import MainIcon2 from "../images/MainIcon2.svg";
import MainIcon3 from "../images/MainIcon3.svg";
import MainIcon4 from "../images/MainIcon4.svg";
import { useDispatch, useSelector } from "react-redux";
import { setProfile } from "../redux/actions/profile_actions";
import { useHistory } from "react-router-dom";
//contents
import AppContext from "../context/AppContext";
import { clearLocalStorage, getInLocalStorage, setInLocalStorage } from "../helpers/local-storage-manager";
import { getAllUsersById, getAllExchangesByStatusAndID } from "../api/facom_services";
import ExchangeRateModal from "../components/molecules/ExchangeRateModal";
import { LoadingPag } from "../components";
import useGlobalContent from "../hooks/useGlobalContent";
import { removeTags } from "../helpers/removeHtml";

function MainPage(props) {
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.profileReducer);
  const { full_name, sub } = profile;
  const { content, initialHistory } = useContext(AppContext);
  console.log(content);
  let history = useHistory();
  const originPath = getInLocalStorage("origin");

  const [open, setOpen] = useState(false);
  const [exchangesToEvaluate, setExchangesToEvaluate] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (props.origin) {
      setInLocalStorage("origin", props.origin);
    } else {
      setInLocalStorage("origin", "");
    }
    if (!exchangesToEvaluate.length) {
      setOpen(false);
    }
  }, [exchangesToEvaluate]);
  const getExchangesByID = async (ExchangeId) => {
    setLoading(true);
    try {
      const response = getAllUsersById(sub);
      const data = (await response).data;
      let dataExchanges = undefined;
      let exchangesWithoutEvaluation = [];
      if (data?.result?.customer_data?._id) {
        const response = getAllExchangesByStatusAndID("Exchanged", data?.result?.customer_data?._id);
        dataExchanges = (await response).data;
        exchangesWithoutEvaluation = dataExchanges?.result.items.filter((item) => item?.evaluation === null || item?.evaluation === 0);
        if (exchangesWithoutEvaluation?.length) {
          setOpen(true);
          setExchangesToEvaluate(exchangesWithoutEvaluation);
        }
      }

      return exchangesWithoutEvaluation;
    } catch (error) {
      console.log("error get details");
      console.log(error);
    } finally {
      console.log("end");
      setLoading(false);
    }
  };

  const onClick = () => {
    // dispatch(clearState())
    const goBack = initialHistory - window.history.length;
    setInLocalStorage("validate", false);
    setInLocalStorage("updatePassword", false);
    dispatch(
      setProfile({
        token: "",
        email: "",
        full_name: "",
        sub: "",
        username: "",
        reload: true,
      })
    );
    clearLocalStorage();
    if (initialHistory !== 1) {
      if (originPath) {
        window.history.go(goBack - 1);
      }
    } else {
      window.location.assign("https://www.google.com/");
    }
  };

  const { replaceGlobalContents } = useGlobalContent();

  const handleRedirectToClaim = async () => {
    try {
      const exchangesWithoutEvaluation = await getExchangesByID();
      if (!exchangesWithoutEvaluation.length) {
        history.push(`/demand/${originPath}`);
      }
    } catch (e) {}
  };
  return (
    <div style={{ margin: "0", padding: "0" }}>
      <div className="row Background_Gray pt-4 px-2" style={{ margin: "0" }}>
        <h2 className="h1-stl Center pt-lg-5 pt-2">
          <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["WARRANTY-HOME-TITLE"]) }} className="main-titles"></div>
        </h2>
        <h3 className="h3-stl Center ">
          <div
            dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["WARRANTY-HOME-SUB-TITLE"]) }}
            className="second-title"
          ></div>
        </h3>
        <h5 className="h5-stl-main Center">
          <div
            dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["WARRANTY-HOME-INFO-TOP"]) }}
            className="description-text"
          ></div>
        </h5>
        <h5 className="h5-stl-main Center" style={{ padding: "0 10%" }}>
          <div
            dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["WARRANTY-HOME-SUB.INFO-TOP"]) }}
            className="description-text"
          ></div>
        </h5>

        <div className="row main-page-box-text" style={{ marginLeft: "0" }}>
          <div className="py-3"></div>
          <div className="col-0 col-xxl-2"></div>
          <div className="col-6 col-lg-3 col-xxl-2 marginMain px-lg-2 pr-2 px-1 mt-3 Center" style={{ padding: "0", margin: "0" }}>
            <div onClick={handleRedirectToClaim} style={{ cursor: "pointer" }}>
              <div style={{ backgroundColor: "white" }} className="py-5">
                <img src={MainIcon1} style={{ width: "20%" }} />
              </div>
              <ButtonMain message={removeTags(`${content["WARRANTY-HOME-SELECT-NEW-WARRANTY"]}`)} />
            </div>
          </div>
          <div className="col-6 col-lg-3 col-xxl-2 marginMain px-lg-2 pr-2 px-1 mt-3 Center" style={{ padding: "0", margin: "0" }}>
            <Link to={`/historic/${originPath}`}>
              <div style={{ backgroundColor: "white" }} className="py-5">
                <img src={MainIcon2} style={{ width: "20%" }} />
              </div>
              <ButtonMain message={removeTags(`${content["WARRANTY-HOME-SELECT-TRACK-WARRANTY"]}`)} />
            </Link>
          </div>
          <div
            className="col-6 col-lg-3 col-xxl-2 marginMain px-lg-2 pr-2 px-1 pt-5 pt-lg-0 mt-3 Center"
            style={{ padding: "0", margin: "0" }}
          >
            <a href="https://www.facom.fr/partage/services/Garantie%20FACOM%202016.pdf" target="_blank">
              <div style={{ backgroundColor: "white" }} className="py-5">
                <img src={MainIcon3} style={{ width: "20%" }} />
              </div>
              <ButtonMain message={removeTags(`${content["WARRANTY-HOME-SELECT-INFO-WARRANTY"]}`)} />
            </a>
          </div>
          <div
            className="col-6 col-lg-3 col-xxl-2 marginMain px-lg-2 pr-2 px-1 pt-5 pt-lg-0 mt-3 Center"
            style={{ padding: "0", margin: "0" }}
          >
            <Link to={`/account/update/${originPath}`}>
              <div style={{ backgroundColor: "white" }} className="py-5">
                <img src={MainIcon4} style={{ width: "20%" }} />
              </div>
              <ButtonMain message={removeTags(`${content["WARRANTY-HOME-SELECT-PERSONAL-INFORMATION"]}`)} />
            </Link>
          </div>
          <div className="col-0 col-xxl-2"></div>
        </div>
        <div className="py-3 "></div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {originPath ? (
            <ButtonLoggout message={removeTags(`${content["WARRANTY-HOME-BUTTON-SING-OUT"]}`)} width="150px" onClick={() => onClick()} />
          ) : (
            <a href={`/login/${originPath}`}>
              <ButtonLoggout message={removeTags(`${content["WARRANTY-HOME-BUTTON-SING-OUT"]}`)} width="150px" onClick={() => onClick()} />
            </a>
          )}
        </div>

        <div className="py-5"></div>
      </div>
      <ExchangeRateModal
        open={open}
        setOpen={setOpen}
        data={exchangesToEvaluate.length ? exchangesToEvaluate : []}
        setExchangesToEvaluate={setExchangesToEvaluate}
      />
      <LoadingPag open={loading} />
    </div>
  );
}

export default MainPage;
