import "./App.css";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Routes from "./Routes";
import FloatAlert from "./components/atoms/FloatAlert";
import useContents from "./hooks/useContents";
import { checkSession } from "./api/facom_services";
import { setInLocalStorage } from "./helpers/local-storage-manager";
import AppContext from "./context/AppContext";

const unlessPaths = [
  "/login",
  "/",
  "/register/professional",
  "/register/individual",
  "/confirm-email",
  "/email-verification",
  "/forgot-password/validate",
];
const MainComponent = (props) => {
  const [alertWrong, setAlertWrong] = useState(false);

  const { initialHistory, setInitialHistory } = useContext(AppContext);
  const { contents } = useSelector((state) => state.profileReducer);
  const { loading } = useContents(contents);
  const unless = (url, paths) => {
    return !paths.findIndex((path) => url.includes(path));
  };

  useEffect(() => {
    const URL = window.location.pathname;

    if (!initialHistory) {
      setInitialHistory(window.history.length);
    }
    try {
      if (URL && !unless(URL, unlessPaths)) {
        checkSession();
      }
    } catch {
      setInLocalStorage("expired", true);
    }
  }, []);

  const renderError = () => {
    return (
      <div>
        {alertWrong && (
          <FloatAlert
            message="Error getting contents"
            show={alertWrong}
            setShow={() => {
              setTimeout(function () {
                setAlertWrong(false);
              }, 4000);
            }}
          />
        )}
      </div>
    );
  };

  return (
    <div>
      <Routes loading={loading} />
      {renderError()}
    </div>
  );
};

export default MainComponent;
