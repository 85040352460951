import { axios as http } from "../frameworks_and_drivers/Axios";
import { BASE_URL } from "../config/settings.json";

const getContentsAPI = async () => {
  try {
    const PATH = "/v1/contents/get-all";
    const { data } = await http.get(`${BASE_URL}${PATH}`);
    const { result } = data || {};

    return result ? mapContents(result.items) : [];
  } catch (error) {
    console.log(error);
    return [];
  }
};
/**
 *
 * @param {Contents[]} contents
 */
const mapContents = (contents) => {
  return contents.map((content) => {
    return {
      id: content.id,
      placeholder: content.placeholder,
      variants: content.variants,
      group_id: content.group_id,
    };
  });
};

/**
 *
 * @param {string} username
 * @param {string} password
 * @returns
 */

const usersLogin = async (username, password) => {
  const body = {
    username,
    password,
    is_admin: false,
  };
  const URL = `${BASE_URL}/v1/users/login`;
  const response = await http.post(URL, body);
  return response;
};

const generateNewPassword = async (email) => {
  const body = {
    email,
  };
  const URL = `${BASE_URL}/v1/users/generate-password-from-end-user`;
  const response = await http.put(URL, body);
  return response;
};

export const getAllUsersById = async (objectId) => {
  const URL = `${BASE_URL}/v1/users/${objectId}/get-one`;
  const response = await http.get(URL);
  return response;
};

export const updateUsers = async (
  objectId,
  first_name,
  last_name,
  email,
  work_phone,
  mobile_phone,
  home_phone,
  address,
  address_complement,
  city,
  country,
  zipcode,
  title,
  lenguage_active,
  preferred_language,
  status,
  fax_number,
  company_name
) => {
  const body = {
    account_type: "Individual",
    first_name,
    last_name,
    email,
    work_phone,
    home_phone,
    mobile_phone,
    fax_number,
    company_name,
    birth_date: "",
    users_settings: {
      lenguage_active,
      preferred_language,
    },
    address: {
      address,
      address_complement,
      city,
      country,
      zipcode,
    },
    title,
    status,
  };
  const URL = `${BASE_URL}/v1/customers/${objectId}/update-one`;
  const response = await http.put(URL, body);
  return response;
};

export const updateUsersLanguage = async (objectId, lenguage_active) => {
  const body = {
    title: "",
    users_settings: {
      lenguage_active,
    },
  };
  const URL = `${BASE_URL}/v1/users/${objectId}/update-one`;
  const response = await http.put(URL, body);
  return response;
};

export const updateUserProfessional = async (
  id,
  account_type,
  business,
  siret_code,
  comments,
  first_name,
  last_name,
  email,
  work_phone,
  home_phone,
  mobile_phone,
  fax_number,
  address,
  address_complement,
  coordinates,
  map_url,
  city,
  country,
  zipcode,
  company_name
) => {
  const body = {
    account_type: "Professional",
    business,
    siret_code,
    terms_accepted: true,
    privacy_policy_accepted: true,
    comments,
    first_name,
    last_name,
    email,
    work_phone,
    home_phone,
    mobile_phone,
    fax_number,
    company_name,
    address: {
      address,
      address_complement,
      coordinates,
      map_url,
      city,
      country,
      zipcode,
    },
  };

  const URL = `${BASE_URL}/v1/customers/${id}/update-one`;
  const { data } = await http.put(URL, body);

  return data;
};

export const setUserPassword = async (objectId, password) => {
  const body = {
    password,
  };
  const URL = `${BASE_URL}/v1/users/${objectId}/set-password`;
  const response = await http.put(URL, body);
  return response;
};

export const getAllProductsByReference = async (reference) => {
  const URL = `${BASE_URL}/v1/products/get-all?limit=10&offset=0&reference=${reference}&available=${true}`;
  const response = await http.get(URL);
  return response;
};

export const getAllProducts = async () => {
  const URL = `${BASE_URL}/v1/products/get-all?&available=${true}`;
  const response = await http.get(URL);
  return response;
};
export const getAllProductsByReferenceDD2 = async () => {
  const URL = `${BASE_URL}/v1/products/get-all?limit=10&offset=0&reference=_UNKNOWN_&available=${true}`;
  const response = await http.get(URL);
  return response;
};

export const getAllReasonExchanges = async () => {
  const URL = `${BASE_URL}/v1/reasons-for-exchange/get-all`;
  const response = await http.get(URL);
  return response;
};

export const getAllExchangesByStatus = async (status) => {
  const URL = `${BASE_URL}/v1/exchanges/get-all?status=${status}`;
  const response = await http.get(URL);
  return response;
};

export const getAllExchangesByStatusAndID = async (status, iD) => {
  const URL = `${BASE_URL}/v1/exchanges/get-all?status=${status}&customer_id=${iD}`;
  const response = await http.get(URL);
  return response;
};

export const getAllExchangesById = async (objectId) => {
  const URL = `${BASE_URL}/v1/exchanges/${objectId}/get-one`;
  const response = await http.get(URL);
  return response;
};

export const createExchange = async (date, customer_id, product_id, exchange_reason_id, comments, pictures) => {
  const body = {
    date,
    customer_id,
    product_id,
    exchange_reason_id,
    comments,
    quantity: 0,
    total_cost: "0.00",
    currency: "€",
    geographical_coordinates: "",
    code_direct_evaluation: "",
    pictures,
  };
  const URL = `${BASE_URL}/v1/exchanges/create-one`;
  const response = await http.post(URL, body);
  return response;
};

export const updateExchangesStatus = async (objectId, status) => {
  const body = {
    status,
  };
  const URL = `${BASE_URL}/v1/exchanges/${objectId}/change-status`;
  const response = await http.put(URL, body);
  return response;
};

export const updateExchanges = async (objectId, customer_id, distributor_id, product_id, exchange_reason_id) => {
  const body = {
    customer_id,
    distributor_id,
    product_id,
    exchange_reason_id,
  };
  const URL = `${BASE_URL}/v1/exchanges/${objectId}/update-one`;
  const response = await http.put(URL, body);
  return response;
};

export const createNewCustomerProfessional = async (
  account_type,
  first_name,
  last_name,
  email,
  mobile_phone,
  postal_code,
  company_name,
  business,
  password,
  terms_accepted,
  privacy_policy_accepted
) => {
  const body = {
    account_type,
    business,
    company_name,
    terms_accepted,
    privacy_policy_accepted,
    first_name,
    last_name,
    email,
    fax_number: "",
    mobile_phone,
    postal_code,
    password,
  };

  console.log(body);
  const URL = `${BASE_URL}/v1/customers/create-one-from-end-user`;
  const response = await http.post(URL, body);
  return response;
};

export const createNewCustomerPersonal = async (
  account_type,
  first_name,
  last_name,
  email,
  mobile_phone,
  address,
  postal_code,
  city,
  password,
  terms_accepted,
  privacy_policy_accepted
) => {
  const body = {
    account_type,
    terms_accepted,
    privacy_policy_accepted,
    first_name,
    last_name,
    email,
    mobile_phone,
    address,
    postal_code,
    city,
    password,
    business: "Others",
    fax_number: "",
  };
  const URL = `${BASE_URL}/v1/customers/create-one-from-end-user`;
  const response = await http.post(URL, body);
  return response;
};

export const getAllDistributors = async (origin) => {
  let URL = `${BASE_URL}/v1/distributors/get-all?is_visible=${true}&sort=desc(evaluation)`;
  if (origin) {
    URL = `${BASE_URL}/v1/distributors/get-all?is_visible=${true}&distributor_set_origin=${origin}&sort=desc(evaluation)`;
  }
  const response = await http.get(URL);
  return response;
};

export const getAllDistributorName = async (filter, origin) => {
  let URL = `${BASE_URL}/v1/distributors/get-all?limit=5&offset=0&name=${filter}&is_visible=${true}&sort=desc(evaluation)`;
  if (origin) {
    URL = `${BASE_URL}/v1/distributors/get-all?limit=5&offset=0&name=${filter}&is_visible=${true}&distributor_set_origin=${origin}&sort=desc(evaluation)`;
  }
  const response = await http.get(URL);
  return response;
};
export const getAllDistributorPostalCode = async (filter, origin) => {
  let URL = `${BASE_URL}/v1/distributors/get-all?limit=5&offset=0&postal_code=${filter}&is_visible=${true}&sort=desc(evaluation)`;
  if (origin) {
    URL = `${BASE_URL}/v1/distributors/get-all?limit=5&offset=0&postal_code=${filter}&is_visible=${true}&distributor_set_origin=${origin}&sort=desc(evaluation)`;
  }
  const response = await http.get(URL);
  return response;
};
export const getAllDistributorAddress = async (filter, origin) => {
  let URL = `${BASE_URL}/v1/distributors/get-all?limit=5&offset=0&address=${filter}&is_visible=${true}&sort=desc(evaluation)`;
  if (origin) {
    URL = `${BASE_URL}/v1/distributors/get-all?limit=5&offset=0&address=${filter}&is_visible=${true}&distributor_set_origin=${origin}&sort=desc(evaluation)`;
  }
  const response = await http.get(URL);
  return response;
};

export const getAllDistributorCity = async (filter, origin) => {
  let URL = `${BASE_URL}/v1/distributors/get-all?limit=5&offset=0&city=${filter}&is_visible=${true}&sort=desc(evaluation)`;
  if (origin) {
    URL = `${BASE_URL}/v1/distributors/get-all?limit=5&offset=0&city=${filter}&is_visible=${true}&distributor_set_origin=${origin}&sort=desc(evaluation)`;
  }
  const response = await http.get(URL);
  return response;
};

export const getAllDistributorPostalCodeAndGroupCode = async (postal_code, group_code, origin) => {
  let URL = `${BASE_URL}/v1/distributors/get-all?limit=5&offset=0&postal_code=${postal_code}&group_code=${group_code}&sort=desc(evaluation)`;
  if (origin) {
    URL = `${BASE_URL}/v1/distributors/get-all?limit=5&offset=0&postal_code=${postal_code}&group_code=${group_code}&distributor_set_origin=${origin}&sort=desc(evaluation)`;
  }
  const response = await http.get(URL);
  return response;
};
// export const getAllDistributorAddress = async (filter) => {
//   const URL = `${BASE_URL}/v1/distributors/get-all?limit=10&offset=0&postal_code=${filter}&is_visible=${true}`;
//   const response = await http.get(URL);
//   return response;
// };

export const getDistributorsById = async (objectId) => {
  const URL = `${BASE_URL}/v1/distributors/${objectId}/get-one`;
  const response = await http.get(URL);
  return response;
};

const service = {
  getContentsAPI,
  usersLogin,
  generateNewPassword,
};
export default service;
export { getContentsAPI, usersLogin, generateNewPassword };

export const addRating = async (objectId, rating) => {
  const body = {
    rating,
  };
  const URL = `${BASE_URL}/v1/exchanges/${objectId}/add-rating`;
  const response = await http.put(URL, body);
  return response;
};

//EMAIL

export const resendVerificationEmailAPI = async (email) => {
  const body = {
    is_admin: false,
    email: email,
  };
  const URL = `${BASE_URL}/v1/users/send-verify-email`;
  const response = await http.post(URL, body);
  return response;
};

export const verifyEmailAPI = async (verificationCode) => {
  const body = {
    verification_code: verificationCode,
  };
  const URL = `${BASE_URL}/v1/users/code-verify-email`;
  const response = await http.post(URL, body);
  return response;
};

export const resendResetPasswordEmailAPI = async (email) => {
  const body = {
    is_admin: false,
    email: email,
  };
  const URL = `${BASE_URL}/v1/users/send-reset-password`;
  const response = await http.post(URL, body);
  return response;
};

export const verifyResetPasswordEmailAPI = async (password, authorizationCode) => {
  const body = {
    password: password,
    autorization_code: authorizationCode,
    is_admin: false,
  };
  const URL = `${BASE_URL}/v1/users/code-confirm-password`;
  const response = await http.post(URL, body);
  return response;
};

export const checkSession = async () => {
  const URL = `${BASE_URL}/v1/users/check-session`;
  const response = await http.get(URL);
  return response;
};

export const updateMigratedUsers = async (uuid, email) => {
  const body = {
    uuid: uuid,
    email_user: email,
    is_admin: false,
  };
  const URL = `${BASE_URL}/v1/users/update-user-migrated`;
  const response = await http.put(URL, body);
  return response;
};

export const updatePassword = async (password, email) => {
  const body = {
    password: password,
    email_user: email,
  };
  const URL = `${BASE_URL}/v1/users/set-password-akamai`;
  const response = await http.put(URL, body);
  return response;
};

export const rateExchangeFromEmail = async (evaluation_code, rating) => {
  const URL = `${BASE_URL}/v1/exchanges/add-rating-from-email`;
  const body = {
    evaluation_code,
    rating,
  };
  const response = await http.put(URL, body);
  return response;
};
