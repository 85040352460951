import { useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../constants/global.css";
import "./style/footerLogin.css";
import ButtonCleanGray from "../atoms/ButtonCleanGray";
import Icon1 from "../../images/Icon1.png";
import Icon2 from "../../images/Icon2.png";
import Icon3 from "../../images/Icon3.png";
import AppContext from "../../context/AppContext";

function FooterLogin() {
  const { content } = useContext(AppContext);

  return (
    <div className="py-5 px-4 Background_Gray2">
      <div className="row">
        <div className="col-0 col-lg-2"></div>
        <div className="col-0 col-lg-2 pt-5 mx-3">
          <h3 className="h3-stl-footer">
            <div dangerouslySetInnerHTML={{ __html: content["IFOOTER.ALL.ITEM1-LIST-TITLE"] }}></div>
          </h3>
          <div dangerouslySetInnerHTML={{ __html: content["IFOOTER.ALL.ITEM1-LIST"] }}></div>
          <h3 className="h3-stl-footer mt-5">
            <div dangerouslySetInnerHTML={{ __html: content["IFOOTER.ALL.ITEM2-LIST-TITLE"] }}></div>
          </h3>
          <div dangerouslySetInnerHTML={{ __html: content["IFOOTER.ALL-ITEM2-LIST"] }}></div>
        </div>
        <div className="col-0 col-lg-2 pt-5 mx-3">
          <h3 className="h3-stl-footer">
            <div dangerouslySetInnerHTML={{ __html: content["IFOOTER.ALL.ITEM3-LIST-TITLE"] }}></div>
          </h3>
          <div dangerouslySetInnerHTML={{ __html: content["IFOOTER.ALL-ITEM3-LIST"] }}></div>

          <h3 className="h3-stl-footer mt-5">
            <div dangerouslySetInnerHTML={{ __html: content["IFOOTER.ALL.ITEM4-LIST-TITLE"] }}></div>
          </h3>
          <div dangerouslySetInnerHTML={{ __html: content["IFOOTER.ALL-ITEM4-LIST"] }}></div>
        </div>
        <div className="col-0 col-lg-1"></div>
        <div className="col-0 col-lg-3 pt-5 mx-3">
          <div style={{ display: "flex" }}>
            <ButtonCleanGray
              message={
                <div className="row" style={{ width: "100%" }}>
                  <div className="col-4" style={{ paddingRight: "0", marginRight: "0" }}>
                    <img src={Icon1} style={{ paddingBottom: "10px", paddingTop: "10px", width: "80%" }}></img>
                  </div>
                  <div className="col-8">
                    <h3 className="h4-stl-footer-btn" style={{ paddingTop: "15px", width: "190px" }}>
                      <div dangerouslySetInnerHTML={{ __html: content["FOOTER.ALL.BUTTON-WORLD-TITLE"] }}></div>
                    </h3>
                  </div>
                </div>
              }
              reference="https://www.facom.com/?_ga=2.258111626.1235616950.1605607184-912071394.1605607184"
            ></ButtonCleanGray>
          </div>
          <div className="pt-1"></div>
          <div style={{ display: "flex" }}>
            <ButtonCleanGray
              message={
                <div className="row" style={{ width: "100%" }}>
                  <div className="col-4" style={{ paddingRight: "0", marginRight: "0" }}>
                    <img src={Icon2} style={{ paddingBottom: "10px", paddingTop: "10px", width: "80%" }}></img>
                  </div>
                  <div className="col-8">
                    <h3 className="h4-stl-footer-btn" style={{ paddingTop: "15px", width: "190px" }}>
                      <div dangerouslySetInnerHTML={{ __html: content["FOOTER.ALL.BUTTON-DISTRIBUTER-TITLE"] }}></div>
                    </h3>
                  </div>
                </div>
              }
              reference="https://www.facom.fr/services/points-de-ventes/Points-de-ventes.html"
            ></ButtonCleanGray>
          </div>
          <div className="pt-1"></div>
          <div style={{ display: "flex" }}>
            <ButtonCleanGray
              message={
                <div className="row" style={{ width: "100%" }}>
                  <div className="col-4" style={{ paddingRight: "0", marginRight: "0" }}>
                    <img src={Icon3} style={{ paddingBottom: "10px", paddingTop: "10px", width: "80%" }}></img>
                  </div>
                  <div className="col-8">
                    <h3 className="h4-stl-footer-btn" style={{ paddingTop: "15px", width: "190px" }}>
                      <div dangerouslySetInnerHTML={{ __html: content["FOOTER.ALL.BUTTON-SALES-TITLE"] }}></div>
                    </h3>
                  </div>
                </div>
              }
              reference="https://www.facom.fr/services/service-apres-vente/service-apres-ventes.html"
            ></ButtonCleanGray>
          </div>
        </div>
        <div className="py-4"></div>
      </div>
    </div>
  );
}

export default FooterLogin;
