import { useSelector } from "react-redux";

export default function useGlobalContent(data) {
  const { profile } = useSelector((state) => state.profileReducer);
  const { full_name, email, language, username } = profile;

  const replaceGlobalContents = (str, itemData) => {
    if (str?.includes("{{username}}")) str = str.replace("{{username}}", `${username}`);
    if (str?.includes("{{email}}")) str = str.replace("{{email}}", `${email}`);
    if (str?.includes("{{language}}")) str = str.replace("{{language}}", `${language}`);
    if (str?.includes("{{full_name}}")) str = str.replace("{{full_name}}", `${full_name}`);
    if (str?.includes("{{distributor_name}}")) str = str.replace("{{distributor_name}}", `${data?.result?.name}`);
    if (str?.includes("{{distributor_address}}")) str = str.replace("{{distributor_address}}", `${data?.result?.address?.address}`);
    if (str?.includes("{{distributor_opening_hours}}"))
      str = str.replace("{{distributor_opening_hours}}", `${data?.result?.address?.opening_hours}`);
    if (str?.includes("{{distributor_phone}}")) str = str.replace("{{distributor_phone}}", `${data?.result?.address.phone}`);
    if (str?.includes("{{distributor_code}}")) str = str.replace("{{distributor_code}}", `${data?.result?.code}`);
    if (str?.includes("{{distributor_evaluation}}")) str = str.replace("{{distributor_evaluation}}", `${data?.result?.evaluation}`);
    if (str?.includes("{{distributor_description}}")) str = str.replace("{{distributor_description}}", `${data?.result?.description}`);
    if (str?.includes("{{distributor_city}}")) str = str.replace("{{distributor_city}}", `${data?.result?.address.city}`);
    if (str?.includes("{{distributor_postal_code}}"))
      str = str.replace("{{distributor_postal_code}}", `${data?.result?.address.postal_code}`);

    if (str?.includes("{{product_name}}")) str = str.replace("{{product_name}}", `${data?.result?.product_data.name}`);
    if (str?.includes("{{product_reference}}")) str = str.replace("{{product_reference}}", `${data?.result?.product_data.reference}`);
    if (str?.includes("{{product_type}}")) str = str.replace("{{product_type}}", `${data?.result?.product_data.type}`);

    if (str?.includes("{{detail_exchange_number}}")) str = str.replace("{{detail_exchange_number}}", `${data?.result?.number}`);
    if (str?.includes("{{detail_exchange_comment}}")) str = str.replace("{{detail_exchange_comment}}", `${data?.result.comments}`);
    if (str?.includes("{{detail_exchange_createdAt}}")) str = str.replace("{{detail_exchange_createdAt}}", `${itemData?.createdAt}`);
    if (str?.includes("{{detail_exchange_distributor_name}}"))
      str = str.replace("{{detail_exchange_distributor_name}}", `${data?.result?.distributor_data?.name}`);
    if (str?.includes("{{detail_exchange_distributor_address}}"))
      str = str.replace("{{detail_exchange_distributor_address}}", `${data?.result?.distributor_data?.address.address}`);
    if (str?.includes("{{detail_exchange_distributor_city}}"))
      str = str.replace("{{detail_exchange_distributor_city}}", `${data?.result?.distributor_data?.address.city}`);
    if (str?.includes("{{detail_exchange_distributor_postal_code}}"))
      str = str.replace("{{detail_exchange_distributor_postal_code}}", `${data?.result?.distributor_data?.address.postal_code}`);
    if (str?.includes("{{detail_exchange_distributor_phone}}"))
      str = str.replace("{{detail_exchange_distributor_phone}}", `${data?.result?.distributor_data?.address.phone}`);
    if (str?.includes("{{detail_exchange_evaluation}}"))
      str = str.replace("{{detail_exchange_evaluation}}", `${data?.result?.distributor_data?.evaluation}`);
    return str;
  };

  return { replaceGlobalContents };
}
