import React, { useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import AppContext from "../../context/AppContext";

import "../../constants/global.css";
import "./style/loginForm.css";
import ButtonRed from "../atoms/Button";
import InputForm from "../atoms/InputForm";
import { resendResetPasswordEmailAPI } from "../../api/facom_services";
import { LoadingFacom, ValidationAlert } from "../atoms";
import { removeTags } from "../../helpers/removeHtml";
import { LocalStorageKeys, setInLocalStorage } from "../../helpers/local-storage-manager";

function RecoverPasswordForm(props) {
  const { content } = useContext(AppContext);
  const [emailRecover, setEmailRecover] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const resetPassword = async () => {
    setLoading(!loading);
    try {
      await resendResetPasswordEmailAPI(emailRecover);
      setInLocalStorage(LocalStorageKeys.EMAIL, "");
      setSuccess(true);
      setError(false);
    } catch (error) {
      console.log("error updated Password");
      console.log(error);
      setError(true);
      setSuccess(false);
    } finally {
      setLoading(false);
      console.log("end");
      // window.location.reload()
    }
  };
  const RenderError = () => {
    return (
      <>
        {error && (
          <div className="my-3">
            <ValidationAlert message={"Email can't be sent"} variant="error" />
          </div>
        )}
      </>
    );
  };
  const RenderSuccess = () => {
    return (
      <>
        {success && (
          <div className="my-3">
            <ValidationAlert message={"Email sent"} severity="success" />
          </div>
        )}
      </>
    );
  };

  return (
    <div className="py-xl-5 px-xl-4 p-3 Background_White">
      <h2 className="h2-stl  Center center__text">
        <div className="font__maxwidth">
          <div dangerouslySetInnerHTML={{ __html: content["FORGOT-PASSWORD-BOX.LOGIN-HOME.TITLE"] }} className="boxes-title"></div>
        </div>
      </h2>
      <div className="line-loggin"></div>
      <div className="row">
        <div className="col-12 col-lg-4">
          <h4 className="h4-stl Rigth-l py-1">
            <div dangerouslySetInnerHTML={{ __html: content["FORGOT-PASSWORD-BOX.LOGIN-HOME.INPUT.EMAIL"] }}></div>
          </h4>
        </div>
        <div className="col-12 col-lg-6">
          <InputForm
            type="text"
            name="User"
            placeholder={removeTags(`${content["LOGIN-HOME-FORGOT-PASSWORD-BOX.INPUT.EMAIL-PLACEHOLDER"]}`)}
            style={{ heigth: "250px" }}
            currentValue={emailRecover}
            handleOnInputValue={(data) => {
              setEmailRecover(data);
            }}
          />
          <RenderSuccess />
          <RenderError />
          <div className="py-2"></div>
          <ButtonRed onClick={() => resetPassword()} message={removeTags(`${content["FORGOT-PASSWORD-BOX.LOGIN-HOME.BUTTON"]}`)} />
        </div>

        <div className="col-0 col-lg-2"></div>
      </div>
      {loading && <LoadingFacom />}
    </div>
  );
}

export default RecoverPasswordForm;
