export const createAdapterDetail = (item) => {
  console.log({ item });
  return {
    result: {
      _id: item.result._id,
      number: item.result.number,
      status: item.result.status.reverse(),
      customer_id: item.result.customer_id,
      distributor_id: item.result.distributor_id,
      product_id: item.result.product_id,
      evaluation: item.result.evaluation,
      qr_code: item.result.qr_code,
      exchange_reason_id: item.result.exchange_reason_id,
      refusal_reason_id: item.result.refusal_reason_id,
      comments: item.result.comments,
      quantity: item.result.quantity,
      total_cost: item.result.total_cost,
      geographical_coordinates: item.result.geographical_coordinates,
      code_direct_evaluation: item.result.code_direct_evaluation,
      currency: item.result.currency,
      pictures: item.result.pictures,
      customer_data: item.result.customer_data,
      distributor_data: item.result.distributor_data,
      product_data: item.result.product_data,
      exchange_reason_data: item.result.exchange_reason_data,
      refusal_reason_data: item.result.refusal_reason_data,
      user_data: item.result.user_data,
      group_data: item.result.group_data,
      brand_data: item.result.brand_data,
    },
  };
};
