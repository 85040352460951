import { useState, useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import AppContext from "../context/AppContext";

// COMPONENTS

import { Link } from "react-router-dom";
import { getAllExchangesByStatusAndID } from "../api/facom_services";
// CSS
import "../constants/global.css";
import "./styles/tranking.css";

import TableState from "../components/atoms/Table";
import LoadingFacom from "../components/atoms/LoadingFacom";
import { useSelector } from "react-redux";
import { getAllUsersById } from "../api/facom_services";
import FloatAlert from "../components/atoms/FloatAlert";
import { removeTags } from "../helpers/removeHtml";
import useGlobalContent from "../hooks/useGlobalContent";
import { getInLocalStorage, setInLocalStorage } from "../helpers/local-storage-manager";

function TrackingState(props) {
  const { profile } = useSelector((state) => state.profileReducer);
  const { sub } = profile;
  const { exchengeCreated, setExchengeCreated } = useContext(AppContext);
  const arrowLeft = "< ";
  const { content } = useContext(AppContext);
  const originPath = getInLocalStorage("origin");
  useEffect(() => {
    if (props.origin) {
      setInLocalStorage("origin", props.origin);
    } else {
      setInLocalStorage("origin", "");
    }
  }, []);
  //Call api

  const [loading, setLoading] = useState(false);
  const [groupExchangesDraft, setGroupExchangesDraft] = useState([]);
  const [groupExchangesSubmitted, setGroupExchangesSubmitted] = useState([]);
  const [groupExchangesAbandonned, setGroupExchangesAbandonned] = useState([]);
  const [groupExchangesAnalysis, setGroupExchangesAnalysis] = useState([]);
  const [groupExchangesAccepted, setGroupExchangesAccepted] = useState([]);
  const [groupExchangesAvailable, setGroupExchangesAvailable] = useState([]);
  const [groupExchangesExchanged, setGroupExchangesExchanged] = useState([]);
  const [groupExchangesRefused, setGroupExchangesRefused] = useState([]);
  const [groupExchangesRecycled, setGroupExchangesRecycled] = useState([]);

  const [alertSuccessStatus, setAlertSuccessStatus] = useState(false);
  const [wrongAlertStatus, setWrongAlertStatus] = useState(false);
  const { replaceGlobalContents } = useGlobalContent();

  //? ------------------------------ALERTS--------------------------------//

  const renderSuccessAbandonned = () => {
    return (
      <div>
        {alertSuccessStatus && (
          <FloatAlert
            message={removeTags(`${content["ALERT-SUCCESS-ABANDONNED"]}`)}
            show={alertSuccessStatus}
            setShow={() => {
              setTimeout(function () {
                setAlertSuccessStatus(false);
              }, 4000);
            }}
            variant="success"
          />
        )}
      </div>
    );
  };

  const alertWrongGet = (error) => {
    setWrongAlertStatus([error]);
  };

  const renderError = () => {
    return (
      <div>
        {wrongAlertStatus && (
          <FloatAlert
            message={removeTags(`${content["ALERT-ERROR-GET-EXCHANGES-STATUS"]}`)}
            show={wrongAlertStatus ? true : false}
            setShow={() => {
              setTimeout(function () {
                setWrongAlertStatus(false);
              }, 4000);
            }}
          />
        )}
      </div>
    );
  };
  const renderSuccess = () => {
    return (
      <div>
        {exchengeCreated && (
          <FloatAlert
            message={removeTags(`${content["ALERT-SUCCESS-CREATE-EXCHANGE"]}`)}
            show={exchengeCreated}
            setShow={() => {
              setTimeout(function () {
                setExchengeCreated(false);
              }, 4000);
            }}
            variant="success"
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    getExchangesStatus();
  }, []);
  const getExchangesStatus = async () => {
    setLoading(!loading);

    try {
      const response = getAllUsersById(sub);
      const data = (await response).data;
      if (data?.result?.customer_data?._id) {
        const response = getAllExchangesByStatusAndID("Draft", data?.result?.customer_data?._id);
        const data0 = (await response).data;
        setGroupExchangesDraft(data0);

        const response2 = getAllExchangesByStatusAndID("Submitted", data?.result?.customer_data?._id);
        const data2 = (await response2).data;
        setGroupExchangesSubmitted(data2);

        const response3 = getAllExchangesByStatusAndID("Abandonned", data?.result?.customer_data?._id);
        const data3 = (await response3).data;
        setGroupExchangesAbandonned(data3);

        const response4 = getAllExchangesByStatusAndID("Analysis", data?.result?.customer_data?._id);
        const data4 = (await response4).data;
        setGroupExchangesAnalysis(data4);

        const response5 = getAllExchangesByStatusAndID("Accepted", data?.result?.customer_data?._id);
        const data5 = (await response5).data;
        setGroupExchangesAccepted(data5);

        const response6 = getAllExchangesByStatusAndID("Available", data?.result?.customer_data?._id);
        const data6 = (await response6).data;
        setGroupExchangesAvailable(data6);

        const response7 = getAllExchangesByStatusAndID("Exchanged", data?.result?.customer_data?._id);
        const data7 = (await response7).data;
        setGroupExchangesExchanged(data7);

        const response8 = getAllExchangesByStatusAndID("Refused", data?.result?.customer_data?._id);
        const data8 = (await response8).data;
        setGroupExchangesRefused(data8);

        const response9 = getAllExchangesByStatusAndID("Recycled", data?.result?.customer_data?._id);
        const data9 = (await response9).data;
        setGroupExchangesRecycled(data9);
      }
      return data;
    } catch (error) {
      console.log("error get products");
      console.log(error);
      alertWrongGet();
    } finally {
      console.log("end");
      setLoading(false);
    }
  };

  return (
    <div>
      <div>
        <div className="row Background_Gray py-5" style={{ margin: "0" }}>
          <Link to={`/dashboard/${originPath}`}>
            <h6 className="h6-return" style={{ display: "flex", gap: "10px" }}>
              {arrowLeft}
              <div dangerouslySetInnerHTML={{ __html: content["FORM.ALL.BACK"] }}></div>
            </h6>
          </Link>
          <div className="row px-3">
            {loading && (
              <div className="py-5">
                {" "}
                <LoadingFacom />
              </div>
            )}
            {!loading && (
              <>
                <div className="mt-4">
                  <h2 className="h1-stl Center pb-lg-5 pb-3">
                    <div
                      dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.TITLE"]) }}
                      className="main-titles"
                    ></div>
                  </h2>
                </div>
                <div className="col-0 col-lg-2"></div>
                <div className="col-12 col-lg-8 Background_White pt-lg-5 pt-2" style={{ textAlign: "center" }}>
                  <div className="row px-3">
                    <div className="col-12 col-lg-1"></div>
                    <div className="col-12 col-lg-10">
                      <div>
                        {groupExchangesDraft?.result?.items.length != 0 && (
                          <div>
                            <h2 className="h2-stl-Track pt-5">
                              <div
                                dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.TITLE-DRAFT"]) }}
                                className="second-title"
                              ></div>
                            </h2>
                            <div className="historic-list"></div>
                            <TableState
                              list={groupExchangesDraft?.result?.items}
                              State={"DRAFT"}
                              reload={() => getExchangesStatus()}
                              alertSuccess={() => setAlertSuccessStatus(true)}
                            />
                          </div>
                        )}
                        {groupExchangesSubmitted?.result?.items.length != 0 && (
                          <div>
                            <h2 className="h2-stl-Track pt-5">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.TITLE-SUBMITTED"]),
                                }}
                                className="second-title"
                              ></div>
                            </h2>
                            <div className="historic-list"></div>
                            <TableState list={groupExchangesSubmitted?.result?.items} State="SOUMIS" />
                          </div>
                        )}
                        {groupExchangesAbandonned?.result?.items.length != 0 && (
                          <div>
                            <h2 className="h2-stl-Track pt-5">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.TITLE-ABANDONNED"]),
                                }}
                                className="second-title"
                              ></div>
                            </h2>
                            <div className="historic-list"></div>
                            <TableState list={groupExchangesAbandonned?.result?.items} State="SOUMIS" />
                          </div>
                        )}
                        {groupExchangesAnalysis?.result?.items.length != 0 && (
                          <div>
                            <h2 className="h2-stl-Track pt-5">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.TITLE-ANALYSIS"]),
                                }}
                                className="second-title"
                              ></div>
                            </h2>
                            <div className="historic-list"></div>
                            <TableState list={groupExchangesAnalysis?.result?.items} State="ANALYSE" />
                          </div>
                        )}
                        {groupExchangesAccepted?.result?.items.length != 0 && (
                          <div>
                            <h2 className="h2-stl-Track pt-5">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.TITLE-ACCEPTED"]),
                                }}
                                className="second-title"
                              ></div>
                            </h2>
                            <div className="historic-list"></div>
                            <TableState list={groupExchangesAccepted?.result?.items} State="ACCEPTÉ" />
                          </div>
                        )}
                        {groupExchangesAvailable?.result?.items.length != 0 && (
                          <div>
                            <h2 className="h2-stl-Track pt-5">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.TITLE-AVAILABLE"]),
                                }}
                                className="second-title"
                              ></div>
                            </h2>
                            <div className="historic-list"></div>
                            <TableState list={groupExchangesAvailable?.result?.items} State="ACCEPTÉ" />
                          </div>
                        )}
                        {groupExchangesExchanged?.result?.items.length != 0 && (
                          <div>
                            <h2 className="h2-stl-Track pt-5">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.TITLE-EXCHANGED"]),
                                }}
                                className="second-title"
                              ></div>
                            </h2>
                            <div className="historic-list"></div>
                            <TableState list={groupExchangesExchanged?.result?.items} State="ACCEPTÉ" />
                          </div>
                        )}
                        {groupExchangesRefused?.result?.items.length != 0 && (
                          <div>
                            <h2 className="h2-stl-Track pt-5">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.TITLE-REFUSED"]),
                                }}
                                className="second-title"
                              ></div>
                            </h2>
                            <div className="historic-list"></div>
                            <TableState list={groupExchangesRefused?.result?.items} State="REFUSÉ" />
                          </div>
                        )}
                        {groupExchangesRecycled?.result?.items.length != 0 && (
                          <div>
                            <h2 className="h2-stl-Track pt-5">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.TITLE-RECYCLED"]),
                                }}
                                className="second-title"
                              ></div>
                            </h2>
                            <div className="historic-list"></div>
                            <TableState list={groupExchangesRecycled?.result?.items} State="REFUSÉ" />
                          </div>
                        )}
                        <div className="py-5"></div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-1"></div>
                  </div>
                </div>
              </>
            )}
            <div className="col-1 col-lg-2"></div>
          </div>
        </div>
      </div>
      {renderSuccess()}
      {renderError()}
      {renderSuccessAbandonned()}
    </div>
  );
}

export default TrackingState;
