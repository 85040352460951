import "bootstrap/dist/css/bootstrap.css";

import "../../constants/global.css";
import "./styles/button.css";

function Button(props) {
  const arrow = "  >";
  return (
    <div>
      <button disabled={props.disabled} type="button" onClick={props.onClick} className="btn-red pt-2">
        <div style={{ marginTop: "2px", marginLeft: "8px" }}>
          <strong>{props.message}</strong>
        </div>

        <h6 style={{ color: "#000", display: "flex", marginBottom: "10px" }} className="px-2">
          <strong> {arrow}</strong>
        </h6>
      </button>
    </div>
  );
}

export default Button;
