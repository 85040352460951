import { useContext, useEffect, useLayoutEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.css";

// COMPONENTS
import LoginForm from "../components/molecules/LoginForm";
import RecoverPasswordForm from "../components/molecules/RecoverPasswordFrom";
import CreateAccount from "../components/molecules/CreateAccount";

// CSS
import "../constants/global.css";
import "./styles/login.css";

//contents
import AppContext from "../context/AppContext";
import { setInLocalStorage } from "../helpers/local-storage-manager";

function LoginPage(props) {
  const { content } = useContext(AppContext);

  useEffect(() => {
    if (props.origin) {
      setInLocalStorage("origin", props.origin);
    } else {
      setInLocalStorage("origin", "");
    }
  }, []);
  return (
    <div style={{ margin: "0", padding: "0" }}>
      <div className="row Background_Gray pt-lg-5 " style={{ margin: "0", paddingRight: "0" }}>
        <h2 className="h1-stl Center pt-lg-5 pt-3">
          <div dangerouslySetInnerHTML={{ __html: content["HEADER.LOGIN-HOME.TITLE"] }} className="main-titles"></div>
        </h2>
        <h3 className="h3-stl Center py-lg-3 pt-2">
          <div dangerouslySetInnerHTML={{ __html: content["HEADER.LOGIN-HOME.SUB-TITLE"] }} className="second-title"></div>
        </h3>
        <div className="row mar_responsive">
          <div className="col-0 col-xl-2 col-lg-1"></div>
          <div className="col-12 col-xl-4 col-lg-5 pt-lg-5 pt-3 responsive-login-boxes">
            <LoginForm />
            <div className="py-lg-4 py-1"></div>
            <RecoverPasswordForm />
          </div>
          <div className="col-12 col-xl-4 col-lg-5 py-lg-5 py-2 responsive-login-boxes">
            <CreateAccount />
          </div>
          <div className="col-0 col-xl-2 col-lg-1"></div>
        </div>
        <div className="row py-lg-5 my-3 ">
          <div className="advantages-start"></div>
          <div className="advantages">
            <div dangerouslySetInnerHTML={{ __html: content["INFO-LIST.LOGIN-HOME-LIST"] }} className="login-description"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
