import { useState, useEffect, useContext } from "react";
import { getAllReasonExchanges, createExchange, updateExchangesStatus, updateExchanges } from "../api/facom_services";
import { useHistory } from "react-router-dom";

import AppContext from "../context/AppContext";
import useAlertWarranty from "./useAlertWarranty";
import { getInLocalStorage } from "../helpers/local-storage-manager";
const useGetExchanges = () => {
  const { distributorID, setExchengeCreated, reasonExchangeID, productID, loading, setLoading } = useContext(AppContext);
  const [loadingLast, setLoadingLast] = useState(false);
  const [reasonForExchangesArray, setReasonForExchangesArray] = useState([]);

  const { alertWrongGet } = useAlertWarranty();

  let history = useHistory();
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const Minutes = date.getMinutes();
  const originPath = getInLocalStorage("origin");
  const TodayDate = `${day}/${month}/${year} ${hour}:${Minutes}`;

  useEffect(() => {
    getAllReasonExchange();
  }, []);

  const getAllReasonExchange = async () => {
    try {
      const response = getAllReasonExchanges();
      const data = (await response).data;
      let filteredTableByDisplayOrder = data.result.items.sort((a, b) => (parseInt(a.display_order) > parseInt(b.display_order) ? 1 : -1));
      // setProductsArray(data)
      setReasonForExchangesArray(
        filteredTableByDisplayOrder.map(function (items) {
          var rObj = {
            label: items.label,
            refused: items.is_for_change,
            id: items.id,
          };
          return rObj;
        })
      );
      return data;
    } catch (error) {
      console.log("error get all reasons for exchange");
      console.log(error);
      alertWrongGet(false, false, false, false, false, true, false, false, false);
    } finally {
      console.log("end");
    }
  };

  //   console.log('IDPRODUCTReference',productReference)

  const [createExchangesID, setCreateExchangesID] = useState("");
  const createNewExchanges = async (userArray, details, picturesArray) => {
    setLoadingLast(!loadingLast);
    try {
      let pictureArrayComplete = [];
      if (picturesArray.length < 4) {
        pictureArrayComplete = picturesArray;
        // for (let i = 0; i < 4 - picturesArray.length; i++) {
        //   pictureArrayComplete = [...pictureArrayComplete, { file_content: null, extension: null }];
        // }
      }
      const response = await createExchange(
        TodayDate,
        userArray.result?.customer_data?._id,
        productID,
        reasonExchangeID,
        details,
        pictureArrayComplete
      );
      setExchengeCreated(true);
      const data = (await response).data;
      setCreateExchangesID(data.result._id);
      return data;
    } catch (error) {
      console.log("error creating new exchange");
      console.log(error);
      alertWrongGet(false, false, false, false, false, false, true, false, false);
    } finally {
      console.log("end");
      //! pPONER ESTE SET FUERAAAA
      //   setPageStatus(6);
      setLoadingLast(false);
    }
  };

  const updateToSpecificStatus = async (button, userArray) => {
    if (distributorID) {
      try {
        await updateExchangesInfo(userArray);
        const response = updateExchangesStatus(createExchangesID, "Submitted");
        const data = (await response).data;
        return data;
      } catch (error) {
        console.log("error updated");
        console.log(error);
        alertWrongGet(false, false, false, false, false, false, false, true, false);
      } finally {
        setLoading(false);
        setExchengeCreated(true);
        console.log("end");
        if (button) {
          history.push(`/historic/${originPath}`);
        } else {
          window.location.replace(`/demand/${originPath}`);
        }
      }
    }
  };

  //UPDATE ALL INFO
  const updateExchangesInfo = async (userArray) => {
    if (distributorID) {
      setLoading(!loading);
      try {
        const response = updateExchanges(
          createExchangesID,
          userArray.result?.customer_data?._id,
          distributorID,
          productID,
          reasonExchangeID
        );
        const data = (await response).data;
        // successUpdated()
        return data;
      } catch (error) {
        console.log("error update info");
        console.log(error);
        alertWrongGet(false, false, false, false, false, false, false, false, true);
      } finally {
        setLoading(false);
        console.log("end");
      }
    }
  };

  return {
    createNewExchanges,
    updateToSpecificStatus,
    reasonForExchangesArray,
    loadingLast,
  };
};

export default useGetExchanges;
