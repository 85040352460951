import { useState, useEffect, useContext } from "react";
import { getAllUsersById } from "../api/facom_services";
import useAlertWarranty from "./useAlertWarranty";
import AppContext from "../context/AppContext";

const useGetUser = (sub) => {
  const { loading, setLoading } = useContext(AppContext);
  const [userArray, setUserArray] = useState([]);
  const { alertWrongGet } = useAlertWarranty();

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    setLoading(!loading);
    try {
      const response = getAllUsersById(sub);
      const data = (await response).data;
      setUserArray(data);
      return data;
    } catch (error) {
      console.log("error getting user");
      console.log(error);
      alertWrongGet(true, false, false, false, false, false, false, false, false);
    } finally {
      console.log("end");
      setLoading(false);
    }
  };

  return { userArray };
};

export default useGetUser;
