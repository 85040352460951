import { ButtonClean, TabWarranty, FileAttachement, ValidationAlert } from "../atoms";
import Example1 from "../../images/Example1.jpeg";
import Example2 from "../../images/Example2.jpeg";
import Example3 from "../../images/Example3.jpeg";
import Example4 from "../../images/Example4.jpeg";
//!DON'T DELETE THOSE IMAGES
import OneStar from "../../images/OneStar.svg";
import TwoStar from "../../images/TwoStars.svg";
import ThreeStar from "../../images/ThreeStar.svg";
import FourStar from "../../images/FourStars.svg";
import FiveStar from "../../images/FiveStars.svg";
import Logo from "../../images/logoEmail.svg";
import useGlobalContent from "../../hooks/useGlobalContent";
import { removeTags } from "../../helpers/removeHtml";
export default function PicturesSection(props) {
  const { pageStatus, lang, content, PhotosStatus, alertImage, assetsMedia } = props;
  const { fileNameImage1, handleChangeStatus, DisabledPhoto1 } = props;
  const { fileNameImage2, handleChangeStatusImage2, DisabledPhoto2 } = props;
  const { fileNameImage3, handleChangeStatusImage3, DisabledPhoto3 } = props;
  const { fileNameImage4, handleChangeStatusImage4, DisabledPhoto4 } = props;
  const { setPageStatusBefore, setPageStatusAfter } = props;
  const { replaceGlobalContents } = useGlobalContent();

  return (
    <div style={{ backgroundColor: "#FFF", borderRadius: "10px" }}>
      <div className="row" style={{ width: "100%" }}>
        <div className="pt-3">
          <TabWarranty pageStatus={pageStatus} />
        </div>

        <div className="col-1 col-lg-2"></div>
        <div className="col-10 col-lg-8">
          <div className="py-4"></div>
          <div className="row">
            <div className="col-6 col-lg-2">
              <FileAttachement
                fileName={fileNameImage1}
                handleChangeStatus={handleChangeStatus}
                contentText={removeTags(`${content["FORM-NEW-WARRANTY-SECOND.STEP-PHOTO-1"]}`)}
                disabled={DisabledPhoto1}
                numberPhoto={1}
                photoStatus={PhotosStatus}
              />
            </div>
            <div className="col-6 col-lg-2">
              <FileAttachement
                fileName={fileNameImage2}
                handleChangeStatus={handleChangeStatusImage2}
                contentText={removeTags(`${content["FORM-NEW-WARRANTY-SECOND.STEP-PHOTO-2"]}`)}
                disabled={DisabledPhoto2}
                numberPhoto={2}
                photoStatus={PhotosStatus}
              />
            </div>
            <div className="col-6 col-lg-2">
              <FileAttachement
                fileName={fileNameImage3}
                handleChangeStatus={handleChangeStatusImage3}
                contentText={removeTags(`${content["FORM-NEW-WARRANTY-SECOND.STEP-PHOTO-3"]}`)}
                disabled={DisabledPhoto3}
                numberPhoto={3}
                photoStatus={PhotosStatus}
              />
            </div>
            <div className="col-6 col-lg-2">
              <FileAttachement
                fileName={fileNameImage4}
                handleChangeStatus={handleChangeStatusImage4}
                contentText={removeTags(`${content["FORM-NEW-WARRANTY-SECOND.STEP-PHOTO-4"]}`)}
                disabled={DisabledPhoto4}
                numberPhoto={4}
                photoStatus={PhotosStatus}
              />
            </div>
            <div className="col-0 col-lg-2"></div>
          </div>
          {fileNameImage1
            ? !fileNameImage3 && (
                <div class="pt-4">
                  <ValidationAlert message={removeTags(`${content["ALERT-MIN-PHOTOS"]}`)} />
                </div>
              )
            : ""}
          {alertImage && (
            <div class="pt-4">
              <ValidationAlert message={removeTags(`${content["ALERT-ERROR-IMAGE-SIZE"]}`)} />
            </div>
          )}
          <div className="py-3"></div>
          {PhotosStatus === 1 && (
            <div>
              <div className="py-3" style={{ textAlign: "left" }}>
                <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-SECOND.P1-STEP-INFO-1"]) }}></div>
              </div>
              <img style={{ display: "flex", alignContent: "left" }} src={Example1}></img>
            </div>
          )}
          {PhotosStatus === 2 && (
            <div>
              <div className="py-3" style={{ textAlign: "left" }}>
                <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-SECOND.STEP-P2-INFO-2"]) }}></div>
              </div>

              <img style={{ display: "flex", alignContent: "left" }} src={Example2}></img>
            </div>
          )}
          {PhotosStatus === 3 && (
            <div>
              <div className="py-3" style={{ textAlign: "left" }}>
                <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-SECOND.P3-STEP-INFO-1"]) }}></div>
              </div>

              <img style={{ display: "flex", alignContent: "left" }} src={Example3}></img>
            </div>
          )}
          {PhotosStatus === 4 && (
            <div>
              <div className="py-3" style={{ textAlign: "left" }}>
                <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-SECOND.P4-STEP-INFO-1"]) }}></div>
              </div>

              <img style={{ display: "flex", alignContent: "left" }} src={Example4}></img>
            </div>
          )}

          <div className="py-4"></div>
          <div className="row">
            <div className="col-6 col-lg-6 py-2">
              <ButtonClean
                message={removeTags(`${content["FORM-NEW-WARRANTY-ALL-INFO-BUTTON-PRE"]}`)}
                onClick={setPageStatusBefore}
                width="120px"
                float="left"
              />
            </div>

            <div className="col-6 col-lg-6 py-2">
              <ButtonClean
                message={removeTags(`${content["FORM-NEW-WARRANTY-ALL-INFO-BUTTON-FOLLOWING"]}`)}
                onClick={setPageStatusAfter}
                disabled={DisabledPhoto4}
                width="120px"
                float="right"
              />
            </div>
            <div className="col-2 col-lg-0"></div>
          </div>
          <div className="py-lg-5 py-3"></div>
        </div>
        <div className="col-1 col-lg-2"></div>
      </div>
    </div>
  );
}
