import { useState, useContext } from "react";
import {
  getAllDistributorAddress,
  getAllDistributorCity,
  getAllDistributorName,
  getAllDistributorPostalCode,
  getAllDistributorPostalCodeAndGroupCode,
  getAllDistributors,
  getDistributorsById,
} from "../api/facom_services";
import AppContext from "../context/AppContext";
import useAlertWarranty from "./useAlertWarranty";
import { getInLocalStorage } from "../helpers/local-storage-manager";
const useGetDistributors = () => {
  const { loading, setLoading } = useContext(AppContext);
  const [loadingDist, setLoadingDist] = useState(false);
  const [distributorsArray, setDistributorsArray] = useState([]);
  const [distributorsIdArray, setDistributorsIdArray] = useState([]);
  const [inputDistributor, setInputDistributor] = useState("");
  const { alertWrongGet } = useAlertWarranty();

  const originPath = getInLocalStorage("origin");

  const getDistributor = async (value) => {
    setLoading(!loading);
    try {
      const handlePostalAndGroupCode = value.split(":");
      let arrayByPostalCodeAndGroupCode = [];
      let completeArray = [];
      if (handlePostalAndGroupCode.length > 1) {
        const responseGroupAndPostal = getAllDistributorPostalCodeAndGroupCode(
          handlePostalAndGroupCode[1],
          handlePostalAndGroupCode[0],
          originPath
        );
        const dataGroupAndPostal = (await responseGroupAndPostal).data;

        arrayByPostalCodeAndGroupCode = dataGroupAndPostal.result.items.map(function (items) {
          // return(items.reference)
          var rObj = {
            name: `${items.address?.postal_code} - ${items.name}`,
            id: items.id,
          };
          return rObj;
        });
      } else {
        const responseByName = getAllDistributorName(value, originPath);
        const responseByPostalCode = getAllDistributorPostalCode(value, originPath);
        const responseByAddress = getAllDistributorAddress(value, originPath);
        const responseByCity = getAllDistributorCity(value, originPath);
        const dataByName = (await responseByName).data;
        const dataByPostalCode = (await responseByPostalCode).data;
        const dataByAddress = (await responseByAddress).data;
        const dataByCity = (await responseByCity).data;

        const fullArray = [
          ...dataByName?.result?.items,
          ...dataByPostalCode?.result?.items,
          ...dataByAddress?.result?.items,
          ...dataByCity?.result?.items,
        ];
        completeArray = fullArray.map(function (items) {
          // return(items.reference)
          var rObj = {
            name: `${items.address?.postal_code} - ${items.name}`,
            id: items.id,
          };
          return rObj;
        });
      }
      let arrayOp = [...completeArray, ...arrayByPostalCodeAndGroupCode];
      let hash = {};
      arrayOp = arrayOp.filter((o) => (hash[o.id] ? false : (hash[o.id] = true)));
      setDistributorsArray(arrayOp);

      return arrayOp;
    } catch (error) {
      console.log("error get distributor");
      console.log(error);
      alertWrongGet(false, false, false, true, false, false, false, false, false);
    } finally {
      console.log("end");
      setLoading(false);
    }
  };

  const getAllDistributor = async () => {
    setLoading(!loading);
    try {
      const response = getAllDistributors(originPath);

      const data = (await response).data;

      // setProductsArray(data)
      const array1 = data.result.items.map(function (items) {
        // return(items.reference)
        var rObj = {
          name: `${items.address?.postal_code} - ${items.name}`,
          id: items.id,
        };
        return rObj;
      });

      setDistributorsArray(array1);

      return data;
    } catch (error) {
      console.log("error get distributors");
      console.log(error);
      alertWrongGet(false, false, false, false, true, false, false, false, false);
    } finally {
      console.log("end");
      setLoading(false);
    }
  };

  const getDistributorID = async (distributorID) => {
    setLoadingDist(!loadingDist);
    try {
      const response = getDistributorsById(distributorID);
      const data = (await response).data;
      setDistributorsIdArray(data);
      return data;
    } catch (error) {
      console.log("error get distributor");
      console.log(error);
      alertWrongGet(false, false, false, false, true, false, false, false, false);
    } finally {
      console.log("end");
      setLoadingDist(false);
    }
  };

  const handleChangeDist = (value) => {
    setInputDistributor(value);
    if (value.slice(0, 1) !== "*") {
      if (value.length >= 2) {
        getDistributor(value);
      }
    }
    if (value === "*") {
      getAllDistributor(originPath);
    }
  };

  return {
    getDistributorID,
    distributorsArray,
    distributorsIdArray,
    inputDistributor,
    handleChangeDist,
    loadingDist,
  };
};

export default useGetDistributors;
