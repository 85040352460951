import { Row } from "@zendeskgarden/react-grid";
import "bootstrap/dist/css/bootstrap.css";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import "./styles/fileAttachement.css";

import UploadImg1 from "../../images/UploadIMG1.svg";
import UploadImg2 from "../../images/UploadIMG2.svg";

const FileAttachement = (props) => {
  const getUploadParams = () => {
    return { url: "https://httpbin.org/post" };
  };

  const handleSubmit = (files, allFiles) => {
    allFiles.forEach((f) => f.remove());
  };

  const contentText = props.contentText;
  // const { getRootProps, getInputProps, isDragActive } = useDropzone({
  //   accept: ['image/jpeg', 'image/png', 'image/pdf'],
  //   onDrop
  // });
  const PhotoStatus = props.photoStatus;
  const NumberOfPhoto = props.numberPhoto;
  var statusUpload = false;

  if (NumberOfPhoto === 1) {
    if (PhotoStatus === 1) {
      statusUpload = true;
    } else {
      statusUpload = false;
    }
  }
  if (NumberOfPhoto === 2) {
    if (PhotoStatus === 2) {
      statusUpload = true;
    } else {
      statusUpload = false;
    }
  }
  if (NumberOfPhoto === 3) {
    if (PhotoStatus === 3) {
      statusUpload = true;
    } else {
      statusUpload = false;
    }
  }
  if (NumberOfPhoto === 4) {
    if (PhotoStatus === 4) {
      statusUpload = true;
    } else {
      statusUpload = false;
    }
  }
  return (
    <Row>
      <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={props.handleChangeStatus}
        onSubmit={handleSubmit}
        // onClick = {props.onClick}
        maxFiles={1}
        disabled={props.disabled}
        handleChangeStatus={(files, allFiles) => {
          allFiles.forEach((f) => f.remove());
        }}
        maxSizeBytes={3145729}
        accept=".jpg,.png,.jpeg"
        styles={
          statusUpload === false
            ? {
                dropzone: {
                  height: "100px",
                  maxWidth: "330px",
                  width: "130px",
                  overflow: "hidden",
                  background: "#FFF",
                  borderRadius: "0",
                  marginLeft: "0",
                  border: "#9b9b9b dotted 2px",
                },
                previewImage: { width: "100px", height: "100px" },
                dropzoneActive: { background: "#e60003" },
                dropzoneReject: { borderColor: "red", backgroundColor: "#DAA" },
                inputLabel: (files, extra) => (extra.reject ? { color: "red" } : {}),
              }
            : {
                dropzone: {
                  height: "100px",
                  maxWidth: "330px",
                  width: "130px",
                  overflow: "hidden",
                  background: "#e60003",
                  borderRadius: "0",
                  marginLeft: "0",
                  border: "#e60003 solid 2px",
                },
                previewImage: { width: "100px", height: "100px" },
                dropzoneActive: { background: "#e60003" },
                dropzoneReject: { borderColor: "red", backgroundColor: "#DAA" },
                inputLabel: (files, extra) => (extra.reject ? { color: "red" } : {}),
              }
        }
        inputContent={(files, extra) =>
          extra.reject ? (
            "Formato no válido"
          ) : statusUpload === false ? (
            <div>
              <div className="pb-3">{contentText}</div>
              <img src={UploadImg2}></img>
            </div>
          ) : (
            <div>
              <div className="pb-3">
                <h3 className="dzu-inputLabel2" style={{ color: "#FFF" }}>
                  {contentText}
                </h3>
              </div>
              <img src={UploadImg1} style={{ width: "35px" }}></img>
            </div>
          )
        }
        SubmitButtonComponent={null}
      />
      {/* <Preview></Preview> */}
      {/* <div className="col-12 col-lg-12 py-3" style={{width:'500px !important',textAlign:'left'}}>
                <Hint style={{width:250,fontWeight:'bold',color:'#469712',textAlign:'left'}}>
                    {`Archivo cargado:${props.fileName}`}
                </Hint> */}
      {/* <Hint style={{width:150 }}>
                    Formatos permitidos: JPG, PNG, y PDF.
                </Hint> */}
      {/* </div> */}
    </Row>
  );
};

export default FileAttachement;
