import { useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";

import "../../constants/global.css";
import "./style/loginForm.css";
import { usersLogin, getAllUsersById } from "../../api/facom_services";
import { decodeJwtToken } from "../atoms/decode";
import Button from "../atoms/Button";
import { useDispatch, useSelector } from "react-redux";
import { setProfile, clearState } from "../../redux/actions/profile_actions";
import InputForm from "../atoms/InputForm";
import LoadingPag from "../atoms/LoadingPag";
import AppContext from "../../context/AppContext";
import useContents from "../../hooks/useContents";
import FloatAlert from "../atoms/FloatAlert";
import { ValidationAlert } from "../atoms";
import { LocalStorageKeys, getInLocalStorage, setInLocalStorage } from "../../helpers/local-storage-manager";
import { removeTags } from "../../helpers/removeHtml";

function LoginForm(props) {
  const { content, successValidationEmail } = useContext(AppContext);
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [alertWrong, setAlertWrong] = useState(false);
  const [alertWrongMessage, setAlertWrongMessage] = useState("");
  // const test = '<div style="color:red">Hola</div>';
  const [alertValidate, setAlertValidate] = useState(getInLocalStorage("validate"));
  const [alertUpdatePassword, setAlertUpdatePassword] = useState(getInLocalStorage("updatePassword"));
  const [alertExpired, setAlertExpired] = useState(getInLocalStorage("expired"));

  const dispatch = useDispatch();
  const { contents } = useSelector((state) => state.profileReducer);
  const { getContents } = useContents(contents);
  const loginAccount = async () => {
    setLoading(!loading);
    try {
      const response = usersLogin(user, password);
      const data = (await response).data;
      if (!data.result?.migrated) {
        const userDec = decodeJwtToken(data?.result?.token, { complete: true });
        //CLEAR LOCAL STORAGE OF MIGRATED USER
        setInLocalStorage(LocalStorageKeys.USER_ID, "");
        setInLocalStorage(LocalStorageKeys.EMAIL, "");
        //CLEAR LOCAL STORAGE OF MIGRATED USER
        if (userDec) {
          dispatch(setProfile({ token: data?.result?.token }));
          dispatch(
            setProfile({
              email: userDec.payload.email,
              full_name: userDec.payload.full_name,
              sub: userDec.payload.sub,
              username: userDec.payload.username,
            })
          );
          //Languaje
          const response = getAllUsersById(userDec.payload.sub);
          const dataUser = (await response).data;
          const activeLanguage = `${dataUser?.result?.users_settings?.lenguage_active}`.toLowerCase();
          let languageACT = "FR";
          if (activeLanguage === "english") {
            languageACT = "EN";
          }
          if (activeLanguage === "french") {
            languageACT = "FR";
          }
          dispatch(setProfile({ language: languageACT }));
          getContents(languageACT, true);
        }
      } else {
        setInLocalStorage(LocalStorageKeys.USER_ID, data.result?.uuid);
        window.location.replace(`/verify-identity`);
      }
    } catch (error) {
      console.log("error login");
      console.log({ error });
      setAlertWrongMessage(`${error?.response?.data?.message}`);
      setAlertWrong(true);
      dispatch(clearState());
    } finally {
      setLoading(false);
      console.log("end");
      setInLocalStorage("validate", false);
      setInLocalStorage("updatePassword", false);
      setInLocalStorage("expired", false);
      setAlertValidate(false);
      setAlertUpdatePassword(false);
      setAlertExpired(false);
    }
  };

  const RenderError = () => {
    return <div className="my-3">{alertWrong && <ValidationAlert message={alertWrongMessage} variant="error" />}</div>;
  };
  const RenderSuccess = () => {
    return (
      <div className="my-3">{alertValidate && <ValidationAlert message={"Account Validate, now you can Login"} severity="success" />}</div>
    );
  };
  const RenderSuccessPassword = () => {
    return (
      <div className="my-3">{alertUpdatePassword && <ValidationAlert message={"Your password has been changed"} severity="success" />}</div>
    );
  };

  const RenderErrorExpired = () => {
    return <div className="my-3">{alertExpired && <ValidationAlert message={"Your session has expired"} severity="error" />}</div>;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    loginAccount();
  };
  const validate1 = getInLocalStorage("validate");
  const validate2 = getInLocalStorage("updatePassword");
  const validate3 = getInLocalStorage("expired");

  return (
    <div className="py-xl-5 px-xl-4 p-3 Background_White">
      <h2 className="h2-stl Center ">
        <div dangerouslySetInnerHTML={{ __html: content["SING-IN-BOX.LOGIN-HOME.TITLE"] }} className="boxes-title"></div>
      </h2>
      <div className="line-loggin"></div>
      <div className="row">
        <div className="col-12 col-lg-4">
          <h4 className="h4-stl Rigth-l py-1">
            <div dangerouslySetInnerHTML={{ __html: content["SING-IN-BOX.LOGIN-HOME.INPUT-EMAIL"] }}></div>
          </h4>
        </div>
        <div className="col-12 col-lg-6">
          <form action="/" onSubmit={handleSubmit}>
            <InputForm
              type="text"
              name="User"
              placeholder={removeTags(`${content["SING-IN-BOX.LOGIN-HOME.INPUT-EMAIL-PLACEHOLDER"]}`)}
              style={{ heigth: "250px" }}
              currentValue={user}
              handleOnInputValue={(data) => {
                setUser(data);
              }}
            />
          </form>
        </div>
        <div className="py-2"></div>
        <div className="col-12 col-lg-4">
          <h4 className="h4-stl Rigth-l pt-3">
            <div dangerouslySetInnerHTML={{ __html: content["SING-IN-BOX.LOGIN-HOME.INPUT-PASSWORD"] }}></div>
          </h4>
        </div>
        <div className="col-12 col-lg-6">
          <form action="/" onSubmit={handleSubmit}>
            <InputForm
              type="password"
              name="User"
              placeholder={removeTags(`${content["SING-IN-BOX.LOGIN-HOME.INPUT-PASSWORD"]}`)}
              style={{ heigth: "250px" }}
              currentValue={password}
              handleOnInputValue={(data) => {
                setPassword(data);
              }}
            />
            <RenderError />
            {validate1 === "true" ? <RenderSuccess /> : <></>}
            {validate2 === "true" ? <RenderSuccessPassword /> : <></>}
            {validate3 === "true" ? <RenderErrorExpired /> : <></>}
          </form>
          <div className="py-1"></div>
          <form action="/" onSubmit={handleSubmit}>
            <input type="submit" value="Connexion" style={{ display: "none" }}></input>
          </form>
          <Button message={removeTags(`${content["SING-IN-BOX.LOGIN-HOME.BUTTON"]}`)} onClick={() => loginAccount()} />
        </div>

        <div className="col-0 col-lg-2"></div>
      </div>
      <LoadingPag open={loading} />
    </div>
  );
}

export default LoginForm;
