import { useContext } from "react";
import AppContext from "../../context/AppContext";
import { removeTags } from "../../helpers/removeHtml";
import useGlobalContent from "../../hooks/useGlobalContent";
import { ButtonClean, LoadingFacom } from "../atoms";
import { DropDownDistributor } from "../molecules";
export default function DistributorSection(props) {
  const { content } = useContext(AppContext);
  const { distributorsArray, selectedPostalCodeFinal } = props;
  const { loadingDist, distributorsIdArray, distributorID } = props;
  const { onSelectDdDist, onChangeDdDist } = props;
  const { setPageStatusBefore, setPageStatusAfter, width } = props;

  const { replaceGlobalContents } = useGlobalContent(distributorsIdArray);
  return (
    <div>
      <div className="row">
        <div className="distributor-box ">
          <DropDownDistributor
            options={distributorsArray}
            selectedItem={selectedPostalCodeFinal}
            onSelect={onSelectDdDist}
            onChange={onChangeDdDist}
          />
        </div>

        <div className="distributor-box ">
          {!loadingDist ? (
            <div>
              {selectedPostalCodeFinal !== removeTags(`${content["FORM-NEW-WARRANTY-FINAL.STEP-PLACEHOLDER"]}`) && (
                <div
                  className="row pt-lg-4 pt-2 mt-lg-5 mt-2"
                  style={{ padding: "0 30px", backgroundColor: "#FFF", borderRadius: "20px", width: "97%", marginLeft: "1%" }}
                >
                  <div className="py-3"></div>
                  <div
                    dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-FINAL.STEP-DISTRIBUTOR-DETAIL"]) }}
                  ></div>

                  <div className="py-3"></div>
                </div>
              )}
            </div>
          ) : (
            <div className="py-5">
              <LoadingFacom />
            </div>
          )}
        </div>

        <div className=" pt-lg-5 pt-3 distributor-box ">
          <ButtonClean
            disabled={!distributorID}
            message={removeTags(`${content["FORM-NEW-WARRANTY-FINAL.STEP-BUTTON-SUBMIT"]}`)}
            onClick={setPageStatusBefore}
            width="120px"
            float="right"
          />
        </div>
        <div className=" pt-4  distributor-box ">
          <ButtonClean
            disabled={!distributorID}
            message={removeTags(`${content["FORM-NEW-WARRANTY-FINAL.STEP-BUTTON-CREATE"]}`)}
            onClick={setPageStatusAfter}
            width="350px"
            maxWidth={width < 450 ? "280px" : "500px"}
            float="right"
          />
        </div>
      </div>
      <div className="col-1 col-lg-2"></div>
    </div>
  );
}
