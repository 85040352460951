import { removeTags } from "../../helpers/removeHtml";
import useGlobalContent from "../../hooks/useGlobalContent";
import { ButtonClean, TabWarranty, TextArea } from "../atoms";

export default function DetailsSection(props) {
  const { pageStatus, content } = props;
  const { details, setDetails, totalCharacters } = props;
  const { setPageStatusBefore, setPageStatusAfter } = props;

  const { replaceGlobalContents } = useGlobalContent();

  return (
    <div style={{ backgroundColor: "#FFF", borderRadius: "10px" }}>
      <div className="row" style={{ width: "100%" }}>
        <div className="pt-3">
          <TabWarranty pageStatus={pageStatus} />
        </div>

        <div className="col-1 col-lg-2"></div>
        <div className="col-10 col-lg-8">
          <div className="py-4"></div>
          <h5 className="h5-stl-warranty Left py-1">
            <div
              dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-FOURTH.STEP-INPUT-DESCRIPTION-TITLE"]) }}
            ></div>
          </h5>
          <TextArea
            name="Code"
            placeholder={removeTags(`${content["FORM-NEW-WARRANTY-FOURTH.STEP-INPUT-DESCRIPTION-PLACEHOLDER"]}`)}
            style={{ height: "350px", width: "100%", padding: "15px" }}
            currentValue={details}
            handleOnInputValue={(data) => {
              setDetails(data);
            }}
            maxLength={600}
          />
          <h5 className="h5-stl-warranty Left py-1">
            <div style={{ display: "flex", gap: "4px" }}>
              <strong>{totalCharacters}</strong>
              <div
                dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-FOURTH.STEP-INPUT-DESCRIPTION-INFO"]) }}
              ></div>
            </div>
          </h5>
          <div className="py-4"></div>
          <div className="row">
            <div className="col-6 col-lg-6 py-2">
              <ButtonClean
                message={removeTags(`${content["FORM-NEW-WARRANTY-ALL-INFO-BUTTON-PRE"]}`)}
                onClick={() => setPageStatusBefore(4)}
                width="120px"
                float="left"
              />
            </div>

            <div className="col-6 col-lg-6 py-2">
              <ButtonClean
                disabled={!details}
                message={removeTags(`${content["FORM-NEW-WARRANTY-ALL-INFO-BUTTON-FOLLOWING"]}`)}
                onClick={() => setPageStatusAfter()}
                width="120px"
                float="right"
              />
            </div>
            <div className="col-2 col-lg-0"></div>
          </div>
          <div className="py-lg-5 py-3"></div>
        </div>
        <div className="col-1 col-lg-2"></div>
      </div>
    </div>
  );
}
