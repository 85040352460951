import ProgressBar from "react-bootstrap/ProgressBar";
import "./style/warrantyprogress.css";
import { useContext } from "react";
import useGlobalContent from "../../hooks/useGlobalContent";
import AppContext from "../../context/AppContext";
export default function WarrantyProgress(props) {
  const { content } = useContext(AppContext);
  const { replaceGlobalContents } = useGlobalContent();
  console.log(props.pageStatus);
  return (
    <div>
      <div className="step-title-mobile mx-3 mt-4">
        <div className="step-title__title">
          <div
            dangerouslySetInnerHTML={{
              __html: replaceGlobalContents(
                props.pageStatus === 2
                  ? content["FORM-NEW-WARRANTY-ALL.TAB-PRODUCT"]
                  : props.pageStatus === 3
                  ? content["FORM-NEW-WARRANTY-ALL.TAB-PICTURES"]
                  : props.pageStatus === 4
                  ? content["FORM-NEW-WARRANTY-ALL.TAB-TYPE"]
                  : content["FORM-NEW-WARRANTY-ALL.TAB-SUBMIT"]
              ),
            }}
          ></div>
        </div>
      </div>
      <div className="flex-progress px-3 pt-3">
        <div style={{ width: "100%" }}>
          <ProgressBar now={props.pageStatus === 2 ? 25 : props.pageStatus === 3 ? 50 : props.pageStatus === 4 ? 75 : 100} />
        </div>
        <div>
          {props.pageStatus === 2 && "1/4"}
          {props.pageStatus === 3 && "2/4"}
          {props.pageStatus === 4 && "3/4"}
          {props.pageStatus === 5 && "4/4"}
        </div>
      </div>
    </div>
  );
}
