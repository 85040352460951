import { useContext, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";

import "../../constants/global.css";
import Table from "react-bootstrap/Table";
import ButtonClean from "./ButtonClean";
import ButtonLoggout from "./ButtonLoggout";
import { updateExchangesStatus } from "../../api/facom_services";
import { Link } from "react-router-dom";
import AppContext from "../../context/AppContext";
import FloatAlert from "./FloatAlert";
import "./styles/tablestyles.css";
import useGlobalContent from "../../hooks/useGlobalContent";
import { removeTags } from "../../helpers/removeHtml";
import { getInLocalStorage } from "../../helpers/local-storage-manager";

function TableState(props) {
  const { content } = useContext(AppContext);
  //Call api
  const [alertWrongStatus, setAlertWrongStatus] = useState(false);
  const { replaceGlobalContents } = useGlobalContent();
  const originPath = getInLocalStorage("origin");
  //Call api
  const alertWrongGet = () => {
    setAlertWrongStatus(true);
  };

  const renderError = () => {
    return (
      <div>
        {alertWrongStatus && (
          <FloatAlert
            message={`${content["ALERT-ERROR-UPDATE-ABANDONNED"]}`}
            show={alertWrongStatus}
            setShow={() => {
              setTimeout(function () {
                setAlertWrongStatus(false);
              }, 4000);
            }}
          />
        )}
      </div>
    );
  };

  const updateStatus = async (id) => {
    try {
      const response = updateExchangesStatus(id, "Abandonned");
      const data = (await response).data;
      props.alertSuccess();
      return data;
    } catch (error) {
      console.log("error update");
      console.log(error);
      alertWrongGet();
    } finally {
      console.log("end");
      props.reload();
    }
  };

  return (
    <div>
      {props.list?.length != 0 ? (
        <Table responsive="xl">
          <thead style={{ marginBottom: "10px", textAlign: "left" }}>
            <tr>
              <th>
                <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.CLAIM-ID"]) }}></div>
              </th>
              <th>
                <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.TITLE-TYPE"]) }}></div>
              </th>
              <th>
                <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.TITLE-BREAKAGE"]) }}></div>
              </th>
              <th>
                <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.TITLE-DATE"]) }}></div>
              </th>
              <th style={{ textAlign: "right", paddingRight: "30px" }}>
                <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.TITLE-ACTIONS"]) }}></div>
              </th>
            </tr>
          </thead>

          <tbody>
            {props.list?.map((row) => (
              <tr style={{ backgroundColor: "rgb(242,242,242)", borderBottom: "5px solid #FFF", textAlign: "left" }}>
                <td style={{ paddingLeft: "15px" }}>{row?.number}</td>
                <td style={{ paddingLeft: "15px" }}>{row?.product_data?.reference}</td>
                <td style={{ paddingLeft: "15px" }}>{row?.exchange_reason_data?.label}</td>
                <td style={{ paddingLeft: "15px" }}>{row.date}</td>
                <td>
                  {props.State === "DRAFT" ? (
                    <div style={{ display: "block" }}>
                      <div style={{ display: "block" }}>
                        <ButtonClean
                          message={removeTags(`${content["TABLE-TRACKING-WARRANTY.BUTTON-GIVE"]}`)}
                          onClick={() => updateStatus(row._id)}
                          width="130px"
                          float="right"
                        />
                      </div>
                      <br />
                      <br />
                      <Link to={originPath ? `/demand-dist/${originPath}/${row._id}` : `/demand-dist/${row._id}`}>
                        <div style={{ display: "block" }}>
                          <ButtonLoggout
                            message={removeTags(`${content["TABLE-TRACKING-WARRANTY.BUTTON-DISTRIBUTOR-CHOICE"]}`)}
                            width="130px"
                          />
                        </div>
                      </Link>
                    </div>
                  ) : (
                    <Link to={originPath ? `/details/${originPath}/${row._id}` : `/details/${row._id}`}>
                      <div>
                        <ButtonLoggout message={removeTags(`${content["TABLE-TRACKING-WARRANTY.BUTTON-DETAIL"]}`)} width="130px" />
                      </div>
                    </Link>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        ""
      )}

      {renderError()}
    </div>
  );
}

export default TableState;
