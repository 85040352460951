import { useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import AppContext from "../../context/AppContext";

import "../../constants/global.css";
import "../molecules/style/loginForm.css";
import ValidationAlert from "./ValidationAlert";
import "./styles/form.css";
import InputForm from "./InputForm";
import { removeTags } from "../../helpers/removeHtml";

function FormLastUser(props) {
  const {
    createPassword,
    setCreatePassword,
    createPasswordConfirm,
    setCreatePasswordConfirm,
    isValid,
    setIsValid,
    isValidSamePass,
    setIsValidSamePass,
  } = useContext(AppContext);

  const { content } = useContext(AppContext);

  if (createPassword === createPasswordConfirm) {
    setIsValidSamePass(true);
  } else {
    setIsValidSamePass(false);
  }

  // function isValidPostalCode() {
  //     const postalCodeRegex = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$-_%^&*]{6,16}$/;;
  //     setIsValid(postalCodeRegex.test(createPassword))
  // }
  // console.log({isValid},{createPassword})
  return (
    <div className="Background_White">
      <div className="row">
        <div className="col-12 col-lg-4 mt-lg-3 mt-0 pt-4">
          <h4 className="h4-stl Rigth-l py-1">
            <div dangerouslySetInnerHTML={{ __html: content["FORM.REGISTER-PROFESSIONAL.PASSWORD"] }}></div>
          </h4>
        </div>
        <div className="col-12 col-lg-6 mt-lg-3 mt-0 padding-form">
          <InputForm
            type="password"
            name="password"
            placeholder={removeTags(`${content["FORM.REGISTER-PROFESSIONAL.PASSWORD"]}`)}
            style={{ heigth: "250px" }}
            currentValue={createPassword}
            handleOnInputValue={(data) => {
              setCreatePassword(data);
              const postalCodeRegex = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$-_%^&*]{6,16}$/;
              setIsValid(postalCodeRegex.test(data));
            }}
          />
        </div>
        <div className="col-12 col-lg-4 pt-2">
          <h4 className="h4-stl Rigth-l py-1">
            <div dangerouslySetInnerHTML={{ __html: content["FORM.REGISTER-PROFESSIONAL.CONFIRMATION"] }}></div>
          </h4>
        </div>
        <div className="col-12 col-lg-6 mt-lg-2 mt-0">
          <InputForm
            type="password"
            name="passwordConf"
            placeholder={removeTags(`${content["FORM.REGISTER-PROFESSIONAL.CONFIRMATION"]}`)}
            style={{ heigth: "250px" }}
            currentValue={createPasswordConfirm}
            handleOnInputValue={(data) => {
              setCreatePasswordConfirm(data);
            }}
          />

          {/* // con && aparece el 0 */}
          {createPassword.length ? (
            <div>
              {!isValid && (
                <div className="pt-4 pb-2">
                  <ValidationAlert message={removeTags(`${content["ALERT-PASSWORD-CONTAIN"]}`)} />
                </div>
              )}
            </div>
          ) : (
            ""
          )}
          <div className="py-2"></div>
          {createPassword.length ? (
            <div>
              {!isValidSamePass && (
                <div>
                  <ValidationAlert message={removeTags(`${content["ALERT-PASSWORD-MATCH"]}`)} />
                </div>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default FormLastUser;
