import React, { useRef, useState, useEffect, useContext } from "react";
import debounce from "lodash.debounce";
import { Item, Menu, Label, Field, Dropdown, Autocomplete } from "@zendeskgarden/react-dropdowns";
import { Row, Col } from "@zendeskgarden/react-grid";
import AppConext from "../../context/AppContext";
import "./style/dropdownDistributor.css";

const DropDownDistributor = (props) => {
  // const [selectedItem, setSelectedItem] = useState(props.options[0]);
  const [inputValue, setInputValue] = useState("");
  const { distributorID, setdistributorID } = useContext(AppConext);

  return (
    <Row justifyContent="left">
      <Col sm={12}>
        <Dropdown
          inputValue={inputValue}
          selectedItem={props.selectedItem}
          onSelect={props.onSelect}
          onInputValueChange={(value) => {
            setInputValue(value);
            props.onChange(value);
          }}
          downshiftProps={{ defaultHighlightedIndex: 0 }}
        >
          <Field>
            <Autocomplete className="warranty-c-dist-autocomplete">{props.selectedItem}</Autocomplete>
          </Field>
          <Menu className="warranty-c-dist-menu">
            {props.options?.length ? (
              props.options.map((option) => (
                <Item key={option.name} value={option.name} onClick={() => setdistributorID(option.id)}>
                  <span>{option.name}</span>
                </Item>
              ))
            ) : (
              <Item disabled>No matches found</Item>
            )}
          </Menu>
        </Dropdown>
      </Col>
    </Row>
  );
};

export default DropDownDistributor;
