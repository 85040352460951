import { useState, useEffect, useContext } from "react";
import AppContext from "../context/AppContext";
import { getAllProducts, getAllProductsByReference, getAllProductsByReferenceDD2 } from "../api/facom_services";
import useAlertWarranty from "./useAlertWarranty";
const useGetProducts = (sub) => {
  const { inputProductSku, setInputProductSku, loading, setLoading } = useContext(AppContext);
  const [productsArray, setProductsArray] = useState([]);
  const [productsReferenceArray, setProductsReferenceArray] = useState([]);
  const { alertWrongGet } = useAlertWarranty();

  useEffect(() => {
    getProductsReference();
  }, []);

  const getProductsReference = async () => {
    setLoading(!loading);
    try {
      const response = getAllProductsByReferenceDD2();
      const data = (await response).data;
      setProductsReferenceArray(
        data.result.items.map(function (items) {
          // return(items.reference)
          var rObj = {
            reference: items.type,
            id: items.id,
          };
          return rObj;
        })
      );
      return data;
    } catch (error) {
      console.log("error get reference products");
      console.log(error);
      alertWrongGet(false, true, false, false, false, false, false, false, false);
      // (() => {
      //   window.location.replace("/account-login");
      // })();
    } finally {
      console.log("end");
      setLoading(false);
    }
  };

  const getProducts = async () => {
    setLoading(!loading);
    try {
      const response = getAllProductsByReference(inputProductSku);
      const data = (await response).data;
      // setProductsArray(data)
      const arrayProducts = data?.result?.items?.map(function (items) {
        // return(items.reference)
        var rObj = {
          reference: items.reference,
          id: items.id,
        };
        return rObj;
      });

      setProductsArray(arrayProducts.filter((item) => !item?.reference?.includes("_UNKNOWN_")));

      return data;
    } catch (error) {
      console.log("error get products");
      console.log(error);
      alertWrongGet(false, false, true, false, false, false, false, false, false);
    } finally {
      console.log("end");
      setLoading(false);
    }
  };

  const getAllProduct = async () => {
    setLoading(!loading);
    try {
      const response = getAllProducts();
      const data = (await response).data;
      // setProductsArray(data)
      const arrayProducts = data?.result?.items?.map(function (items) {
        // return(items.reference)
        var rObj = {
          reference: items.reference,
          id: items.id,
        };
        return rObj;
      });

      setProductsArray(arrayProducts.filter((item) => !item?.reference?.includes("_UNKNOWN_")));
      return data;
    } catch (error) {
      console.log("error get products");
      console.log(error);
      alertWrongGet(false, false, true, false, false, false, false, false, false);
    } finally {
      console.log("end");
      setLoading(false);
    }
  };
  const handleChange = (value) => {
    setInputProductSku(value);
    if (value.slice(0, 1) !== "*") {
      if (value.length >= 2) {
        getProducts(value);
      }
    }
    if (value === "*") {
      getAllProduct(value);
    }
  };

  return {
    productsArray,
    productsReferenceArray,
    handleChange,
  };
};

export default useGetProducts;
