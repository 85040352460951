import axios from "axios";
import { STORE } from "../redux";

import { clearState, setProfile } from "../redux/actions/profile_actions";
import { getInLocalStorage, LocalStorageKeys, setInLocalStorage } from "../helpers/local-storage-manager";
import { filter, first, lastValueFrom, Subject } from "rxjs";
import { HTTPCodesEnum } from "../models/httpcodes.models";

const unlessPaths = [
  "/login",
  "/",
  "/register/professional",
  "/register/individual",
  "/confirm-email",
  "/email-verification",
  "/forgot-password/validate",
];

const refreshToken = () => {
  return axios.post(
    "http.refrashToken",
    {},
    {
      headers: {
        Authorization: getInLocalStorage(LocalStorageKeys.REFRESH_TOKEN),
      },
    }
  );
};

const SetInterceptors = () => {
  let data = {};

  const listener = () => {
    data = STORE.getState().profileReducer.profile;
  };

  STORE.subscribe(listener);

  const unless = (url, paths) => {
    return !paths.findIndex((path) => url.includes(path));
  };
  //request:AxiosRequestConfig -> type
  const updateHeader = (request) => {
    //If localStorage managment
    // const token = getInLocalStorage(LocalStorageKeys.TOKEN);
    const token = data.token;
    const newHeaders = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    request.headers = newHeaders;
    return request;
  };
  const redirect = () => {
    // clearLocalStorage();
    STORE.dispatch(clearState());
    // window.location.replace("/account-login");
  };

  //RefreshToken
  let refreshingToken = false;
  //Subject<string | null>()
  const refreshTokenSubject = new Subject();
  //AxiosRequestConfig -> type
  const refreshTokenLogic = async (request) => {
    if (!refreshingToken) {
      refreshingToken = true;
      refreshTokenSubject.next(null);
      return refreshToken()
        .then((response) => {
          refreshingToken = false;
          return axios.request(updateHeader(request));
        })
        .catch((error) => {
          redirect();
          throw new Error("Error to refresh token");
        });
    }
    const token = await lastValueFrom(
      refreshTokenSubject.pipe(
        filter((token) => token !== null),
        first()
      )
    );
    //If localStorage managment
    // token && setInLocalStorage(LocalStorageKeys.TOKEN, token);
    token &&
      STORE.dispatch(
        setProfile({
          token: token,
        })
      );
    return axios.request(updateHeader(request));
  };

  const onRequest = (request) => {
    const URL = request.url;

    if (URL && !unless(URL, unlessPaths))
      if (request.headers && data.token) {
        // request.headers["Authorization"] = `Bearer ${data.token}`;
        updateHeader(request);
        // request.headers[
        //   "Authorization"
        // ] = `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjYxOTUzOWIyNGUwYTBlMzlhZWYyMDBkNSJ9.eyJ1c2VybmFtZSI6InRlc3Q3QHRlc3QuY29tIiwiZW1haWwiOiJ0ZXN0N0B0ZXN0LmNvbSIsImZ1bGxfbmFtZSI6InRlc3QgdGVzdCIsInJvbGVzIjpbIjYyZjE3NmE1ODAyNTQwMDAxMTU0ZWUyMyJdLCJyb2xlc19kYXRhIjpbeyJpZCI6IjYyZjE3NmE1ODAyNTQwMDAxMTU0ZWUyMyIsIm5hbWUiOiJGYWNvbSBDbGllbnQiLCJ2aWV3c19lbmFibGVkIjpudWxsfV0sImlhdCI6MTY1OTk5MjQ2OCwiZXhwIjoxNjc3MjcyNDY4LCJpc3MiOiJmYWNvbSIsInN1YiI6IjYyZDA4OGIyMDg1ZmExMDAxMTZkMThjMiIsImp0aSI6ImE2ZWIwYzk0LWFkYjktNGZhMy1iZTg4LTFmNzA3NTY2NDIwMSJ9.Bk3wqi7qz_6ZHjAe3A5cbKN7YwWSbc5TwbWRub2vcWDCyiyNHZ8s2X9g0u6W6X_GHIT8UIhs0cefWRDH2XW26GxaNXcz5GyJJKKPlIxqaudJzLolsi8oavXQKgUurgUcnm0BId4qnZRLZlPWRf67hlKMJQKqow5cXgbEzl2Tr2JDhI0oLwJgZX2pVfN_ncCY9gf52qWqgKEQVzucUT-RuYUqN8mna9OFpYR3logy07bGsTKOQtNxlIGvMoFDM9p1jaUQ8HWsWmeSlK7_fQiXT5grsnOtsbuTG83fkhaLE7OpLyDJNPXjpxHhs2vZpJS7Bvd8yaNfgwftrPQVsFb2SA`;
      }
    return request;
  };

  const onError = (error) => {
    return Promise.reject(error);
  };

  axios.interceptors.request.use(onRequest, onError);

  axios.interceptors.response.use(
    (response) => {
      console.log("response");
      return response;
    },
    (error) => {
      const URL = window.location.pathname;
      if (URL && !unless(URL, unlessPaths)) {
        if (error?.response?.status === HTTPCodesEnum.UNAUTHORIZED || error?.response?.status === HTTPCodesEnum.FORBIDDEN) {
          setInLocalStorage("expired", true);
          redirect();
        }
      }

      return Promise.reject(error);
    }
  );
};

const service = {
  axios,
};
export default service;
export { axios, SetInterceptors };
