import logo from "./logo.svg";
import "./App.css";
import AppContext from "./context/AppContext";
import useglobalStates from "./hooks/globalStates";
import MainComponent from "./MainComponent";
import { Provider } from "react-redux";
import { STORE as store, PERSISTOR } from "./redux";
import { PersistGate } from "redux-persist/integration/react";
import { SetInterceptors } from "./frameworks_and_drivers/Axios";
import "./constants/variables.css";
import "./constants/content.css";

SetInterceptors();

const App = () => {
  const globalState = useglobalStates();
  const loggedIn = true;

  return (
    <AppContext.Provider value={globalState}>
      <Provider store={store}>
        <PersistGate persistor={PERSISTOR}>
          <MainComponent loggedIn={loggedIn} />
        </PersistGate>
      </Provider>
    </AppContext.Provider>
  );
};

export default App;
