import { useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";

// COMPONENTS
import ProfessionalForm from "../components/molecules/ProfessionalFrom";
import { Link } from "react-router-dom";
// CSS
import "../constants/global.css";
import "./styles/login.css";
import "../components/molecules/style/loginForm.css";
import AppContext from "../context/AppContext";
import { getInLocalStorage, setInLocalStorage } from "../helpers/local-storage-manager";

function ParticularRegister(props) {
  const arrowLeft = "< ";
  const { content } = useContext(AppContext);

  const originPath = getInLocalStorage("origin");
  useEffect(() => {
    if (props.origin) {
      setInLocalStorage("origin", props.origin);
    } else {
      setInLocalStorage("origin", "");
    }
  }, []);

  return (
    <div>
      <div className="row Background_Gray py-5" style={{ margin: "0" }}>
        <a href={`/login/${originPath}`}>
          <h6 className="h6-return" style={{ display: "flex", gap: "10px" }}>
            {arrowLeft}
            <div dangerouslySetInnerHTML={{ __html: content["FORM.ALL.BACK"] }}></div>
          </h6>
        </a>
        <div className="row">
          <div className="col-0 col-lg-4"></div>
          <div className="col-12 col-lg-6" style={{ maxWidth: "750px", textAlign: "center" }}>
            <h2 className="h1-stl Center pt-5 ">
              <div dangerouslySetInnerHTML={{ __html: content["FORM.REGISTER-PERSONAL.TITLE"] }} className="main-titles"></div>
            </h2>
            <h3 className="h3-stl Center py-3 ">
              <div dangerouslySetInnerHTML={{ __html: content["FORM.REGISTER-PERSONAL.SUB-TITLE"] }} className="second-title"></div>
            </h3>
            <div className="my-5">
              <ProfessionalForm typeOfClient="Particular" />
            </div>
          </div>
          <div className="col-0 col-lg-2"></div>
        </div>
      </div>
    </div>
  );
}

export default ParticularRegister;
