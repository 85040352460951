import { removeTags } from "../../helpers/removeHtml";
import useGlobalContent from "../../hooks/useGlobalContent";
import { ButtonClean, TabWarranty } from "../atoms";
import { DropDownProduct, DropDownSKU } from "../molecules";
export default function ProductSelection(props) {
  const { pageStatus, handleChangeToTerms, content } = props;
  const { productsArray, selectedProductReference, setSelectedProductReference, handleChangeSKU } = props;
  const { productsReferenceArray, selectedProductReferenceByName, setSelectedProductReferenceByName } = props;
  const { productID, handleChangeToStep2 } = props;

  const { replaceGlobalContents } = useGlobalContent();

  return (
    <div style={{ backgroundColor: "#FFF", borderRadius: "10px" }}>
      <div className="row" style={{ width: "100%" }}>
        <div className="pt-3">
          <TabWarranty pageStatus={pageStatus} />
        </div>
        <div className="col-1 col-lg-2"></div>
        <div className="col-10 col-lg-8">
          <div className="py-4"></div>
          <h5 className="h5-stl-warranty Left py-1">
            <div
              dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-FIRST.STEP-INPUT-KNOW-TITLE"]) }}
            ></div>
          </h5>
          <DropDownSKU
            options={productsArray}
            selectedItem={selectedProductReference}
            onSelect={setSelectedProductReference}
            onChange={handleChangeSKU}
          />
          <div className="py-lg-3 py-2"></div>

          <h5 className="h5-stl-warranty Center">
            <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-FIRST.STEP-OR"]) }}></div>
          </h5>

          <div className="py-lg-3 py-1"></div>
          <h5 className="h5-stl-warranty Left py-1">
            <div
              dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-FIRST.STEP-INPUT-DONT-KNOW-TITLE"]) }}
            ></div>
          </h5>
          <DropDownProduct
            options={productsReferenceArray}
            selectedItem={selectedProductReferenceByName}
            onSelect={setSelectedProductReferenceByName}
          />
          <div className="py-4"></div>
          <div className="row">
            <div className="col-6 col-lg-6 py-2" style={{ textAlign: "left !important" }}>
              <ButtonClean
                message={removeTags(`${content["FORM-NEW-WARRANTY-ALL-INFO-BUTTON-PRE"]}`)}
                onClick={handleChangeToTerms}
                width="120px"
                float="left"
              />
            </div>
            {/* <div className="col-0 col-lg-8"></div> */}

            <div className="col-6 col-lg-6 py-2">
              <ButtonClean
                message={removeTags(`${content["FORM-NEW-WARRANTY-ALL-INFO-BUTTON-FOLLOWING"]}`)}
                onClick={handleChangeToStep2}
                disabled={!productID}
                width="120px"
                float="right"
              />
            </div>
            <div className="col-0 col-lg-0"></div>
          </div>
          <div className="py-lg-5 py-3"></div>
        </div>
        <div className="col-1 col-lg-2"></div>
      </div>
    </div>
  );
}
