import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getContentsAPI } from "../api/facom_services";
import AppContext from "../context/AppContext";
import { setInLocalStorage } from "../helpers/local-storage-manager";
import { Template } from "../pages/pageTemplates/template";
import { setProfile } from "../redux/actions/profile_actions";

export default function useContentsDetails() {
  const dispatch = useDispatch();
  const [loadingCont, setLoadingCont] = useState(false);
  const { profile } = useSelector((state) => state.profileReducer);
  const { language } = profile;
  const { setContent, toggle } = useContext(AppContext);

  const getContents = async (language, detailGroupId) => {
    setLoadingCont(true);
    try {
      const items = await getContentsAPI();
      //Process items to get only one variant
      const processedItems = processItems(items, language, Template, detailGroupId);
      dispatch(setProfile({ contents: processedItems }));
      setContent(processedItems);
    } catch (err) {
      setInLocalStorage("validate", false);
      setInLocalStorage("updatePassword", false);
      throw err;
    }
    setLoadingCont(false);
  };

  /**
   *
   * @param {contents} items saved on redux
   * @param {string} language language selected by user
   * @param {any} titleObjects template of component
   * @returns
   */
  const processItems = (items, language, titleObjects, detailGroupId) => {
    //Create an array from key of component template
    const keyTitles = Object.keys(titleObjects);

    //Iterate this array and extract language variant from contents
    for (let i = 0; i < keyTitles.length; i++) {
      const element = keyTitles[i];
      //Search content that match with template placeholder
      let content = {};
      if (detailGroupId) {
        //Search if content have group id and update based on it
        content = items.find((item) => item.placeholder === element && item.group_id === detailGroupId);
      } else {
        content = items.find((item) => item.placeholder === element);
      }

      if (content) {
        //Search language variant according user selection
        const variant = content.variants.find((variant) => variant.language === language);
        if (variant) titleObjects[element] = variant.content;
      }
    }

    return titleObjects;
  };

  /**
   *
   * @param {contents} items saved on redux
   * @param {string} language language selected by user
   * @param {any} titleObjects template of component
   * @returns
   */

  return { getContents, processItems, loadingCont };
}
