import { removeTags } from "../../helpers/removeHtml";
import useGlobalContent from "../../hooks/useGlobalContent";
import { ButtonClean, TabWarranty } from "../atoms";
import { DropDownReasonEx } from "../molecules";

export default function TypeBreakSection(props) {
  const { pageStatus, content } = props;
  const { reasonForExchangesArray, selectedTypeCase, setselectedTypeCase } = props;
  const { setPageStatusBefore, setPageStatusAfter, reasonExchangeID } = props;

  const { replaceGlobalContents } = useGlobalContent();

  return (
    <div style={{ backgroundColor: "#FFF", borderRadius: "10px" }}>
      <div className="row" style={{ width: "100%" }}>
        <div className="pt-3">
          <TabWarranty pageStatus={pageStatus} />
        </div>

        <div className="col-1 col-lg-2"></div>
        <div className="col-10 col-lg-8">
          <div className="py-4"></div>
          <h5 className="h5-stl-warranty Left py-1">
            <div
              dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-THIRD.STEP-INPUT-KNOW-TITLE"]) }}
            ></div>
          </h5>

          <DropDownReasonEx options={reasonForExchangesArray} selectedItem={selectedTypeCase} onSelect={setselectedTypeCase} />
          <div className="py-3"></div>

          <div className="py-4"></div>
          <div className="row">
            <div className="col-6 col-lg-6 py-2">
              <ButtonClean
                message={removeTags(`${content["FORM-NEW-WARRANTY-ALL-INFO-BUTTON-PRE"]}`)}
                onClick={setPageStatusBefore}
                width="120px"
                float="left"
              />
            </div>

            <div className="col-6 col-lg-6 py-2">
              <ButtonClean
                message={removeTags(`${content["FORM-NEW-WARRANTY-ALL-INFO-BUTTON-FOLLOWING"]}`)}
                disabled={!reasonExchangeID}
                onClick={setPageStatusAfter}
                width="120px"
                float="right"
              />
            </div>
            <div className="col-2 col-lg-0"></div>
          </div>
          <div className="py-lg-5 py-3"></div>
        </div>
        <div className="col-1 col-lg-2"></div>
      </div>
    </div>
  );
}
