import React, { useRef, useState, useEffect } from "react";

import franceBrand from "../../images/franceBrand.svg";
import englishBrand from "../../images/englishBrand.svg";
import spainBrand from "../../images/spainBrand.svg";
import germanyBrand from "../../images/germanyBrand.png";

import debounce from "lodash.debounce";
import { Item, Menu, Label, Field, Dropdown, Autocomplete } from "@zendeskgarden/react-dropdowns";
import { Row, Col } from "@zendeskgarden/react-grid";

import "../../constants/global.css";

const DropDownCities = (props) => {
  // const [selectedItem, setSelectedItem] = useState(props.options[0]);
  const [inputValue, setInputValue] = useState("");
  const [matchingOptions, setMatchingOptions] = useState(props.options);

  /**
   * Debounce filtering
   */
  const filterMatchingOptionsRef = useRef(
    debounce((value) => {
      const matchedOptions = props.options.filter((option) => option.trim().toLowerCase().indexOf(value.trim().toLowerCase()) !== -1);

      setMatchingOptions(matchedOptions);
    }, 300)
  );

  useEffect(() => {
    filterMatchingOptionsRef.current(inputValue);
  }, [inputValue]);

  var filteredDropdown = [""];
  if (props.options) {
    filteredDropdown = props.options.filter((option) => {
      return option.trim().toLowerCase().indexOf(inputValue.trim().toLowerCase()) !== -1;
    });
  }

  return (
    <Row style={{ justifyContent: "left", width: "106%" }}>
      <Col sm={12}>
        <Dropdown
          inputValue={inputValue}
          selectedItem={props.selectedItem}
          onSelect={props.onSelect}
          onInputValueChange={(value) => setInputValue(value)}
          downshiftProps={{ defaultHighlightedIndex: 0 }}
        >
          <Field>
            <Autocomplete
              disabled={props.disabled}
              style={{
                background: `${props.backgroud}`,
                color: "#818181",
                borderRadius: "2px",
                textAlign: "left",
                fontFamily: "Arial",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "14px",
                lineHeight: "16px",
                width: `${props.width ? props.width : "98%"}`,
                height: "30px",
                border: "1px solid #ced4da",
              }}
            >
              {props.selectedItem === "France" ? (
                <img src={franceBrand} style={{ paddingRight: "5px" }}></img>
              ) : props.selectedItem === "United Kingdom" ? (
                <img src={englishBrand} style={{ paddingRight: "5px" }}></img>
              ) : props.selectedItem === "Spain" ? (
                <img src={spainBrand} style={{ paddingRight: "5px" }}></img>
              ) : props.selectedItem === "Germany" ? (
                <img src={germanyBrand} style={{ paddingRight: "5px" }}></img>
              ) : (
                ""
              )}
              {props.selectedItem === "French" ? (
                <img src={franceBrand} style={{ paddingRight: "5px" }}></img>
              ) : props.selectedItem === "English" ? (
                <img src={englishBrand} style={{ paddingRight: "5px" }}></img>
              ) : (
                ""
              )}
              {props.selectedItem}
            </Autocomplete>
          </Field>
          <Menu
            style={{
              backgroundColor: "#FFF",
              color: "#818181",
              borderRadius: "2px",
              fontFamily: "Arial",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "14px",
              lineHeight: "16px",
            }}
          >
            {filteredDropdown.length ? (
              filteredDropdown.map((option) => (
                <Item key={option} value={option}>
                  {option === "France" ? (
                    <img src={franceBrand} style={{ paddingRight: "5px" }}></img>
                  ) : option === "United Kingdom" ? (
                    <img src={englishBrand} style={{ paddingRight: "5px" }}></img>
                  ) : option === "Spain" ? (
                    <img src={spainBrand} style={{ paddingRight: "5px" }}></img>
                  ) : option === "Germany" ? (
                    <img src={germanyBrand} style={{ paddingRight: "5px" }}></img>
                  ) : (
                    ""
                  )}

                  {option === "French" ? (
                    <img src={franceBrand} style={{ paddingRight: "5px" }}></img>
                  ) : option === "English" ? (
                    <img src={englishBrand} style={{ paddingRight: "5px" }}></img>
                  ) : (
                    ""
                  )}
                  <span>{option}</span>
                </Item>
              ))
            ) : (
              <Item disabled>No matches found</Item>
            )}
          </Menu>
        </Dropdown>
      </Col>
    </Row>
  );
};

export default DropDownCities;
