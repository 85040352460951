import { useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import AppContext from "../../context/AppContext";

import "../../constants/global.css";
import "../molecules/style/loginForm.css";
import InputForm from "./InputForm";

import "./styles/form.css";
import { removeTags } from "../../helpers/removeHtml";
function FormProfessionalUser() {
  const { zipCode, setZipCode, companyName, setCompanyName, isValidSiret, setIsValidSiret, content } = useContext(AppContext);

  return (
    <div className="Background_White">
      <div className="row">
        {/* Falta sacar lo que se escribe*/}
        <div className="col-12 col-lg-4 pt-lg-4 pt-3">
          <h4 className="h4-stl Rigth-l py-1">
            <div dangerouslySetInnerHTML={{ __html: content["FORM.REGISTER-PROFESSIONAL.POSTAL-CODE"] }}></div>
          </h4>
        </div>
        <div className="col-12 col-lg-6 padding-form">
          <InputForm
            type="text"
            name="Code Postal"
            placeholder={removeTags(`${content["FORM.REGISTER-PROFESSIONAL.POSTAL-CODE"]}`)}
            style={{ heigth: "250px" }}
            currentValue={zipCode}
            handleOnInputValue={(data) => {
              setZipCode(data);
            }}
          />
        </div>
        <div className="col-12 col-lg-4 pt-2">
          <h4 className="h4-stl Rigth-l py-1">
            <div dangerouslySetInnerHTML={{ __html: content["FORM.REGISTER-PROFESSIONAL.SIRET"] }}></div>
          </h4>
        </div>
        <div className="col-12 col-lg-6 pt-lg-2 pt-0">
          <InputForm
            type="text"
            name="Numéro SIRET"
            placeholder={removeTags(`${content["FORM.REGISTER-PROFESSIONAL.SIRET"]}`)}
            style={{ heigth: "250px" }}
            currentValue={companyName}
            handleOnInputValue={(data) => {
              setCompanyName(data);
              // const postalCodeRegex = /^([0-9]{10})?$/;
              // setIsValidSiret(postalCodeRegex.test(data));
            }}
          />

          {/* {companyName.length ? (
            <div>
              {!isValidSiret && (
                <div className="py-4">
                  <ValidationAlert message="It must be a 10 digit number" />
                </div>
              )}
            </div>
          ) : (
            ""
          )} */}
        </div>
      </div>
    </div>
  );
}

export default FormProfessionalUser;
