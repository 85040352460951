import "./App.css";
import { useContext, useEffect } from "react";

import { BrowserRouter, Switch, Route } from "react-router-dom";

import LoginPage from "./pages/Login";
import ProfessionalRegister from "./pages/ProfessionalRegister";
import ParticularRegister from "./pages/ParticularRegister";
import PersonalInfoForm from "./pages/PersonalInfoForm";
import WarrantyPage from "./pages/WarrantyPage";
import MainPage from "./pages/MainPage";
import TrackingState from "./pages/TrakingState";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import DetailsExchange from "./pages/DetailsExchange";
import FooterLogin from "./components/molecules/FooterLogin";
// CSS
import "./constants/global.css";
import "./pages/styles/login.css";
import AppContext from "./context/AppContext";
import SelectDistributor from "./pages/SelectDistributor";
import { useWindowSize } from "./hooks";
import { LoadingFacom, NavMenuEnd } from "./components";
import VerifyEmail from "./pages/VerifyEmail";
import EmailValidation from "./pages/EmailValidation";
import UpdatePassword from "./pages/UpdatePassword";
import TestAlert from "./components/atoms/TestAlert";
import VerifyIdentity from "./pages/VerifyIdentity";
import FacEval from "./pages/FacEval";

const Routes = (props) => {
  const { width } = useWindowSize();
  const { version } = useContext(AppContext);

  //Redux
  const { profile } = useSelector((state) => state.profileReducer);
  const { token } = profile;
  const { loading } = props;

  return (
    <BrowserRouter>
      <div>
        <div style={window.location.pathname.includes("/ext/FacEval") ? { display: "none" } : {}}>
          <NavMenuEnd />
        </div>
        <>
          {!loading ? (
            <>
              {token !== "" ? (
                <Switch>
                  <Redirect exact from="/" to="/dashboard"></Redirect>
                  <Redirect exact from="/login/:origin" to="/dashboard/:origin"></Redirect>
                  <Redirect exact from="/login" to="/dashboard"></Redirect>

                  <Route
                    exact
                    path={["/dashboard", "/dashboard/:origin"]}
                    render={(routerProps) => <MainPage origin={routerProps.match.params.origin} />}
                  />
                  <Route
                    exact
                    path={["/confirm-email/:origin", "/confirm-email"]}
                    render={(routerProps) => <EmailValidation origin={routerProps.match.params.origin} />}
                  />
                  <Route
                    exact
                    path={["/ext/FacEval/:origin", "/ext/FacEval"]}
                    render={(routerProps) => <FacEval origin={routerProps.match.params.origin} />}
                  />
                  <Route
                    exact
                    path={["/verify-identity/:origin", "/verify-identity"]}
                    render={(routerProps) => <VerifyIdentity origin={routerProps.match.params.origin} />}
                  />
                  <Route
                    exact
                    path={["/email-verification/:origin", "/email-verification"]}
                    render={(routerProps) => <VerifyEmail origin={routerProps.match.params.origin} />}
                  />
                  <Route
                    exact
                    path={["/forgot-password/validate/:origin", "/forgot-password/validate"]}
                    render={(routerProps) => <UpdatePassword origin={routerProps.match.params.origin} />}
                  />

                  <Route
                    exact
                    path={["/register/professional/:origin", "/register/professional"]}
                    render={(routerProps) => <ProfessionalRegister origin={routerProps.match.params.origin} />}
                  />
                  <Route
                    exact
                    path={["/register/individual/:origin", "/register/individual"]}
                    render={(routerProps) => <ParticularRegister origin={routerProps.match.params.origin} />}
                  />
                  <Route
                    exact
                    path={["/account/update/:origin", "/account/update"]}
                    render={(routerProps) => <PersonalInfoForm origin={routerProps.match.params.origin} />}
                  />
                  <Route
                    exact
                    path={["/demand/:origin", "/demand"]}
                    render={(routerProps) => <WarrantyPage origin={routerProps.match.params.origin} />}
                  />
                  <Route
                    exact
                    path={["/historic/:origin", "/historic"]}
                    render={(routerProps) => <TrackingState origin={routerProps.match.params.origin} />}
                  />
                  <Route
                    exact
                    path={["/details/:origin/:id", "/details/:id"]}
                    render={(routerProps) => <DetailsExchange id={routerProps.match.params.id} origin={routerProps.match.params.origin} />}
                  />
                  <Route
                    exact
                    path={["/demand-dist/:origin/:id", "/demand-dist/:id"]}
                    render={(routerProps) => (
                      <SelectDistributor id={routerProps.match.params.id} origin={routerProps.match.params.origin} />
                    )}
                  />
                </Switch>
              ) : (
                <Switch>
                  <Redirect exact from="/" to="/login"></Redirect>
                  <Redirect exact from="/dashboard/:origin" to="/login/:origin"></Redirect>
                  <Redirect exact from="/dashboard" to="/login"></Redirect>
                  <Redirect exact from="/account/update/:origin" to="/login/:origin"></Redirect>
                  <Redirect exact from="/account/update" to="/login"></Redirect>
                  <Redirect exact from="/demand/:origin" to="/login/:origin"></Redirect>
                  <Redirect exact from="/demand" to="/login"></Redirect>
                  <Redirect exact from="/historic/:origin" to="/login/:origin"></Redirect>
                  <Redirect exact from="/historic" to="/login"></Redirect>
                  <Redirect exact from="/details/:origin/:id" to="/login/:origin"></Redirect>
                  <Redirect exact from="/details/:id" to="/login"></Redirect>
                  <Redirect exact from="/demand-dist/:id" to="/login"></Redirect>
                  <Redirect exact from="/demand-dist/:origin/:id" to="/login/:origin"></Redirect>
                  <Route
                    exact
                    path={["/ext/FacEval/:origin", "/ext/FacEval"]}
                    render={(routerProps) => <FacEval origin={routerProps.match.params.origin} />}
                  />
                  <Route
                    exact
                    path={["/login/:origin", "/login"]}
                    render={(routerProps) => <LoginPage origin={routerProps.match.params.origin} />}
                  />
                  <Route
                    exact
                    path={["/confirm-email/:origin", "/confirm-email"]}
                    render={(routerProps) => <EmailValidation origin={routerProps.match.params.origin} />}
                  />
                  <Route
                    exact
                    path={["/verify-identity/:origin", "/verify-identity"]}
                    render={(routerProps) => <VerifyIdentity origin={routerProps.match.params.origin} />}
                  />
                  <Route
                    exact
                    path={["/email-verification/:origin", "/email-verification"]}
                    render={(routerProps) => <VerifyEmail origin={routerProps.match.params.origin} />}
                  />
                  <Route
                    exact
                    path={["/forgot-password/validate/:origin", "/forgot-password/validate"]}
                    render={(routerProps) => <UpdatePassword origin={routerProps.match.params.origin} />}
                  />
                  <Route
                    exact
                    path={["/register/professional/:origin", "/register/professional"]}
                    render={(routerProps) => <ProfessionalRegister origin={routerProps.match.params.origin} />}
                  />
                  <Route
                    exact
                    path={["/register/individual/:origin", "/register/individual"]}
                    render={(routerProps) => <ParticularRegister origin={routerProps.match.params.origin} />}
                  />
                </Switch>
              )}
            </>
          ) : (
            <div style={{ height: "60vh", backgroundColor: "rgb(28, 30, 31)", display: "flex", alignItems: "center" }}>
              <LoadingFacom />
            </div>
          )}
        </>

        {width > 991 && <div>{!window.location.pathname.includes("/ext/FacEval") && <FooterLogin />}</div>}

        <TestAlert />
        <div className="py-2 " style={{ backgroundColor: "#000" }}>
          <div className="row">
            <h5 style={{ fontSize: "10px" }} className="h5-stl-footer Center">
              Version {version}
            </h5>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default Routes;
