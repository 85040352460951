import { useState } from "react";

const useGetPictures = () => {
  const [picturesArray, setPicturesArray] = useState([]);
  const [fileNameImage1, setFileNameImage1] = useState("");
  const [fileNameImage2, setFileNameImage2] = useState("");
  const [fileNameImage3, setFileNameImage3] = useState("");
  const [fileNameImage4, setFileNameImage4] = useState("");
  var PhotosStatus = 1;
  var DisabledPhoto1 = false;
  var DisabledPhoto2 = true;
  var DisabledPhoto3 = true;
  var DisabledPhoto4 = true;

  if (fileNameImage1 != "") {
    PhotosStatus = 2;
    DisabledPhoto2 = false;
  }
  if (fileNameImage2 != "") {
    PhotosStatus = 3;
    DisabledPhoto3 = false;
  }
  if (fileNameImage3 != "") {
    PhotosStatus = 4;
    DisabledPhoto4 = false;
  }

  //FILE ATTACHEMENT
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();
      // Convert the file to base64 text
      reader.readAsDataURL(file);
      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const [alertImage, setAlertImage] = useState(false);

  const handleChangeStatus = async (meta, status, files) => {
    console.log(status);
    if (status === "done") {
      const base64File = await getBase64(files[0].file);
      setFileNameImage1(files[0]?.file?.name);

      let base64noType = "";
      if (files[0]?.file?.type === "image/png" || files[0]?.file?.type === "image/jpg") {
        base64noType = base64File.slice(22);
      } else if (files[0]?.file?.type === "image/jpeg") {
        base64noType = base64File.slice(23);
      }
      setPicturesArray([...picturesArray, { file_content: base64noType, extension: files[0]?.file?.type }]);
    } else if (status === "error_file_size") {
      files.forEach((f) => f.remove());
      setFileNameImage1("");
      PhotosStatus = 1;
      DisabledPhoto2 = true;
      setAlertImage(true);
    } else {
      setAlertImage(false);
    }
    if (status === "removed") {
      setFileNameImage1("");
    }
  };
  const handleChangeStatusImage2 = async (meta, status, files) => {
    if (status === "done") {
      const base64File = await getBase64(files[0].file);
      setFileNameImage2(files[0].file.name);
      let base64noType = "";
      if (files[0]?.file?.type === "image/png" || files[0]?.file?.type === "image/jpg") {
        base64noType = base64File.slice(22);
      } else if (files[0]?.file?.type === "image/jpeg") {
        base64noType = base64File.slice(23);
      }
      setPicturesArray([...picturesArray, { file_content: base64noType, extension: files[0]?.file?.type }]);
    } else if (status === "error_file_size") {
      files.forEach((f) => f.remove());
      setFileNameImage2("");
      PhotosStatus = 2;
      DisabledPhoto3 = true;
      setAlertImage(true);
    } else {
      setAlertImage(false);
    }
    if (status === "removed") {
      setFileNameImage2("");
    }
  };
  const handleChangeStatusImage3 = async (meta, status, files) => {
    if (status === "done") {
      const base64File = await getBase64(files[0].file);
      setFileNameImage3(files[0].file.name);
      let base64noType = "";
      if (files[0]?.file?.type === "image/png" || files[0]?.file?.type === "image/jpg") {
        base64noType = base64File.slice(22);
      } else if (files[0]?.file?.type === "image/jpeg") {
        base64noType = base64File.slice(23);
      }
      setPicturesArray([...picturesArray, { file_content: base64noType, extension: files[0]?.file?.type }]);
    } else if (status === "error_file_size") {
      files.forEach((f) => f.remove());
      setFileNameImage3("");
      PhotosStatus = 3;
      DisabledPhoto4 = true;
      setAlertImage(true);
    } else {
      setAlertImage(false);
    }
    if (status === "removed") {
      setFileNameImage3("");
    }
  };
  const handleChangeStatusImage4 = async (meta, status, files) => {
    if (status === "done") {
      const base64File = await getBase64(files[0].file);
      setFileNameImage4(files[0].file.name);
      let base64noType = "";
      if (files[0]?.file?.type === "image/png" || files[0]?.file?.type === "image/jpg") {
        base64noType = base64File.slice(22);
      } else if (files[0]?.file?.type === "image/jpeg") {
        base64noType = base64File.slice(23);
      }
      setPicturesArray([...picturesArray, { file_content: base64noType, extension: files[0]?.file?.type }]);
    } else if (status === "error_file_size") {
      files.forEach((f) => f.remove());
      setFileNameImage4("");
      PhotosStatus = 4;
      setAlertImage(true);
    } else {
      setAlertImage(false);
    }
    if (status === "removed") {
      setFileNameImage4("");
    }
  };

  return {
    picturesArray,
    fileNameImage1,
    fileNameImage2,
    fileNameImage3,
    fileNameImage4,
    PhotosStatus,
    DisabledPhoto1,
    DisabledPhoto2,
    DisabledPhoto3,
    DisabledPhoto4,
    handleChangeStatus,
    handleChangeStatusImage2,
    handleChangeStatusImage3,
    handleChangeStatusImage4,
    alertImage,
  };
};

export default useGetPictures;
