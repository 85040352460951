import React, { useContext } from "react";

import AppContext from "../../context/AppContext";
import FloatAlert from "./FloatAlert";

export default function TestAlert() {
  const { globalAlert, setGlobalAlert, globalAlertMessage } = useContext(AppContext);
  return (
    <div>
      {globalAlert && (
        <FloatAlert
          message={`${globalAlertMessage}`}
          show={globalAlert}
          setShow={() => {
            setTimeout(() => {
              setGlobalAlert(false);
            }, 4000);
          }}
          variant="success"
        />
      )}
    </div>
  );
}
