import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getContentsAPI } from "../api/facom_services";
import AppContext from "../context/AppContext";
import { setInLocalStorage } from "../helpers/local-storage-manager";
import { Template } from "../pages/pageTemplates/template";
import { setProfile } from "../redux/actions/profile_actions";

export default function useContents(contentsRedux) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { profile } = useSelector((state) => state.profileReducer);
  const { language } = profile;
  const { setContent, toggle } = useContext(AppContext);
  useEffect(() => {
    getContents(language);
  }, [toggle]);

  const getContents = async (language, changeLanguage) => {
    try {
      if (!contentsRedux || changeLanguage) {
        const items = await getContentsAPI();
        //Process items to get only one variant
        const processedItems = processItems(items, language, Template);
        dispatch(setProfile({ contents: processedItems }));
        setContent(processedItems);
      } else {
        setContent(contentsRedux);
      }
    } catch (err) {
      setInLocalStorage("validate", false);
      setInLocalStorage("updatePassword", false);
      throw err;
    }
  };

  /**
   *
   * @param {contents} items saved on redux
   * @param {string} language language selected by user
   * @param {any} titleObjects template of component
   * @returns
   */
  const processItems = (items, language, titleObjects) => {
    //Create an array from key of component template
    const keyTitles = Object.keys(titleObjects);
    //Iterate this array and extract language variant from contents
    for (let i = 0; i < keyTitles.length; i++) {
      const element = keyTitles[i];
      //Search content that match with template placeholder
      const content = items.find((item) => item.placeholder === element);
      if (content) {
        //Search language variant according user selection
        const variant = content.variants.find((variant) => variant.language === language);
        if (variant) titleObjects[element] = variant.content;
      }
    }

    return titleObjects;
  };

  /**
   *
   * @param {contents} items saved on redux
   * @param {string} language language selected by user
   * @param {any} titleObjects template of component
   * @returns
   */

  return { getContents, processItems, loading };
}
