import "bootstrap/dist/css/bootstrap.css";

import "../../constants/global.css";
import "./styles/buttonClear.css";

function ButtonClean(props) {
  return (
    <div>
      <button
        disabled={props.disabled}
        type="button"
        style={{
          width: `${props.width}`,
          display: "flex",
          float: `${props.float}`,
          maxWidth: `${props.maxWidth}`,
          backgroundColor: props.color,
          borderColor: props.borderColor,
        }}
        onClick={props.onClick}
        className="btn-red-cl pt-2"
      >
        <span>
          <strong>{props.message}</strong>
        </span>
      </button>
    </div>
  );
}

export default ButtonClean;
