import { useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import AppContext from "../../context/AppContext";

import "../../constants/global.css";
import "./style/loginForm.css";
import ButtonClean from "../atoms/ButtonClean";

import professionalImage from "../../images/Professionaljpg.jpeg";
import particularImage from "../../images/Particular.jpg";
import { removeTags } from "../../helpers/removeHtml";
import { getInLocalStorage } from "../../helpers/local-storage-manager";
import { useMediaQuery } from "@mui/material";

function CreateAccount(props) {
  const { content } = useContext(AppContext);
  const originPath = getInLocalStorage("origin");
  const matches = useMediaQuery("(min-width:991px)");
  return (
    <div className="py-xl-5 px-xl-4 p-3 Background_White">
      <h2 className="h2-stl Center ">
        <div dangerouslySetInnerHTML={{ __html: content["CREATE-BOX.LOGIN-HOME.TITLE"] }} className="boxes-title"></div>
      </h2>
      <div className="line-loggin"></div>
      <div className="row">
        <div className="col-1"></div>
        <div className="col-12 col-lg-5 mx-1 mt-3" style={{ padding: "0", margin: "0 -10px" }}>
          {matches && <img src={professionalImage} style={{ width: "100%" }} />}
          <a href={`/register/professional/${originPath}`}>
            <ButtonClean message={removeTags(`${content["CREATE-BOX.LOGIN-HOME.PROFESSIONAL"]}`)} width="100%" />
          </a>
        </div>

        <div className="col-12 col-lg-5 mx-1 mt-3" style={{ padding: "0", margin: "0" }}>
          {matches && <img src={particularImage} style={{ width: "100%" }} />}
          <a href={`/register/individual/${originPath}`}>
            <ButtonClean message={removeTags(`${content["CREATE-BOX.LOGIN-HOME.PARTICULAR"]}`)} width="100%" />
          </a>
        </div>
        <div className="col-1"></div>
        <div className="col-0 col-lg-2"></div>
      </div>
    </div>
  );
}

export default CreateAccount;
