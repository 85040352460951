import React, { useState } from "react";

import Alert from "@material-ui/lab/Alert";

import "./styles/ValidationAlert.css";

export default function FloatAlert(props) {
  const { message, variant = "error", show, setShow } = props;
  if (show) {
    setShow();
  }
  return (
    <div>
      <div
        className={
          show
            ? "warranty-c-alert-stl-fcm warranty-c-fade-in-fcm"
            : "warranty-c-alert-stl-fcm"
        }
      >
        {show && <Alert severity={variant}>{message}</Alert>}
      </div>
    </div>
  );
}
